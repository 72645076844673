  import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
  import { clients } from 'src/app/utils/common/content';


  import * as $ from 'jquery';
  import { throttleTime } from 'rxjs/operators';
  import { debounce, throttle } from 'lodash';

  @Component({
    selector: 'app-services-new',
    templateUrl: './services-new.component.html',
    styleUrls: ['./services-new.component.scss']
  })
  export class ServicesNewComponent implements OnInit {

    constructor() { }
    a:number=99

    ngOnInit(): void {
      document.addEventListener('mousemove',this.updateMouseCursor)
      document.addEventListener('touchmove',this.updateMouseCursor)
      this.moveUp= debounce(this.moveUp,500)
      this.moveDown= debounce(this.moveDown,500)
    }


    updateMouseCursor(e: any){
      let el= document.getElementById('light');
      if(el){
        el.style.top= (e.pageY-230)+'px';
        el.style.left= (e.pageX-230)+'px';
      }
    }




    
    allClients= clients;

    // background color switch
    scrollPosition: number= 0;

    backgroundColorHome: string='#191B1D';
    colorTextHome: string= '#fff';

    @ViewChild("mainBlock") mainDiv: ElementRef;
    @ViewChild("banner") bannerDiv: ElementRef;
    @ViewChild("inds") industryDiv: ElementRef;
    @ViewChild("para") paraDiv: ElementRef;
    @ViewChild("work") workDiv: ElementRef;
    @ViewChild("contactUs") contactDiv: ElementRef;
    @ViewChild("ind") indDiv: ElementRef;
    indDivHeight: any='748px';
    activeIndSlide:number= 0
    // scrooling position 
    ngAfterViewInit(): void {
      var heightCheck;
      let dialogElement = this.mainDiv.nativeElement as HTMLElement;
        dialogElement.onscroll = () => {
            this.scrollPosition= dialogElement.scrollTop;
            this.changeBgColor();
        }
      this.marqueeScroll()
      setTimeout(() => {
        this.indDivHeight= Math.max(this.indDiv.nativeElement.scrollHeight, this.indDiv.nativeElement.offsetHeight, this.indDiv.nativeElement.clientHeight)
      }, 300);
      // const slider = $(".serviceBodyHai");
      //     slider.on('wheel', ((e)=> {
      //         heightCheck= Math.max(this.bannerDiv.nativeElement.scrollHeight+this.industryDiv.nativeElement.scrollHeight, this.bannerDiv.nativeElement.offsetHeight+this.industryDiv.nativeElement.offsetHeight, this.bannerDiv.nativeElement.clientHeight+this.industryDiv.nativeElement.clientHeight) - window.innerHeight

      //         var btnD= $(".upss")
      //         var btnU= $(".downss")

      //         if(this.activeIndSlide<this.industries.length-1 && this.scrollPosition>heightCheck){
      //           // e.preventDefault()
      //         }
      //         if (e.originalEvent.deltaY < 0 &&  this.scrollPosition<heightCheck+300) {
      //             if(this.activeIndSlide>0){
      //               btnU.click()
      //               e.preventDefault()
      //             }
      //         }
      //         if (e.originalEvent.deltaY > 0 && this.scrollPosition>heightCheck ) {
      //           if(this.activeIndSlide<this.industries.length-1 && this.scrollPosition>heightCheck){                  
      //             btnD.click()
      //             e.preventDefault()
      //           }
      //       }

      //     }));
          
  this.runSlider()
    }
    runSlider(){
      setInterval(()=>{
        if(this.activeIndSlide<this.industries.length-1){
          this.activeIndSlide+=1
        }else{
          this.activeIndSlide=0
        }
      }, 2000)
    }


    moveUp(){
      console.log('sup')
      if(this.activeIndSlide<this.industries.length-1)
        this.activeIndSlide+=1
      else{
        console.log("psu")
      }
      console.log('up')
    }
    moveDown(){
      console.log('down')
      if(this.activeIndSlide>0)
      this.activeIndSlide-=1
    }

    changeBgColor(){
      var bannerHeight= Math.max(this.bannerDiv.nativeElement.scrollHeight, this.bannerDiv.nativeElement.offsetHeight, this.bannerDiv.nativeElement.clientHeight);
      var industryHeight= Math.max(this.indDiv.nativeElement.scrollHeight, this.indDiv.nativeElement.offsetHeight, this.indDiv.nativeElement.clientHeight);
      var paraHeight= Math.max(this.paraDiv.nativeElement.scrollHeight, this.paraDiv.nativeElement.offsetHeight, this.paraDiv.nativeElement.clientHeight);
      var workHeight= Math.max(this.workDiv.nativeElement.scrollHeight, this.workDiv.nativeElement.offsetHeight, this.workDiv.nativeElement.clientHeight);
      var contactUsHeight= Math.max(this.contactDiv.nativeElement.scrollHeight, this.contactDiv.nativeElement.offsetHeight, this.contactDiv.nativeElement.clientHeight);

      if(this.scrollPosition<this.getColorChangeHeight(bannerHeight) && this.scrollPosition>0){
        this.backgroundColorHome= "#191B1D"
        this.colorTextHome= "#fff"
      }
      if(this.scrollPosition<this.getColorChangeHeight(industryHeight)+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(bannerHeight)){
        this.backgroundColorHome= '#191B1D'
        this.colorTextHome= '#fff'
      }
      if(this.scrollPosition<this.getColorChangeHeight(paraHeight)+industryHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(industryHeight)+bannerHeight){
        this.backgroundColorHome= '#b7babe'
        this.colorTextHome= '#111'
      }
      if(this.scrollPosition<this.getColorChangeHeight(workHeight)+paraHeight+industryHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(paraHeight)+industryHeight+bannerHeight){
        this.backgroundColorHome= '#191B1D'
        this.colorTextHome= '#fff'
      }
      if(this.scrollPosition<this.getColorChangeHeight(contactUsHeight)+workHeight+paraHeight+industryHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(workHeight)+paraHeight+industryHeight+bannerHeight){
        this.backgroundColorHome= '#ffffff'
        this.colorTextHome= '#000'
      }    
    }
    getColorChangeHeight(n: number){
      return n - (this.mainDiv.nativeElement.clientHeight * 0.4)
      // return n*0.8
    }

    industries: Array<any>= [
      {
        image: '/assets/images_new/services/img1.png',
        head: 'Education',
        info: 'One of the most important part of our process are the visuals. There is a solid reason as to why we select a particular color or why we go with a particular theme.'
      },
      {
        image: '/assets/images_new/services/img2.png',
        head: 'Real Estate',
        info: 'One of the most important part of our process are the visuals. There is a solid reason as to why we select a particular color or why we go with a particular theme.'
      },
      {
        image: '/assets/images_new/services/imgs3.png',
        head: 'Automobile',
        info: 'One of the most important part of our process are the visuals. There is a solid reason as to why we select a particular color or why we go with a particular theme.'
      },
      {
        image: '/assets/images_new/services/img4.png',
        head: 'E-commerce',
        info: 'One of the most important part of our process are the visuals. There is a solid reason as to why we select a particular color or why we go with a particular theme.'
      },
      {
        image: '/assets/images_new/services/img2.png',
        head: 'Real Estate',
        info: 'One of the most important part of our process are the visuals. There is a solid reason as to why we select a particular color or why we go with a particular theme.'
      },
    ]

    marqueeScroll() {
      const marquees = document.querySelectorAll("div.marquee");
      marquees.forEach(function (element: any) {
        let tick = 1;
        let value = element.dataset.speed;
        // element.innerHTML += element.innerHTML;
        element.innerHTML += element.innerHTML;
        element.innerHTML += element.innerHTML;

        const innerTags = element.querySelectorAll("div.inner");
        innerTags.forEach((inner, index) => {
          inner.style.left = inner.offsetWidth * index + "px";
        });

        const ticker = function () {
          tick += parseInt(value);
          //element.innerHTML = tick;
          //element.style.left = tick + "px";
          innerTags.forEach((inner, index) => {
            let width = inner.offsetWidth;
            let normalizedMarqueeX = ((tick % width) + width) % width;
            let pos = width * (index - 1) + normalizedMarqueeX;

            inner.style.left = pos + "px";
          });
          requestAnimationFrame(ticker);
        };
        ticker();
      });
    }




    preventDefault(e) {
      e.preventDefault();
    }

  }
