<div class="header">
    <app-header-new [textColor]="'#000'"></app-header-new>
</div>
<div class="main container2">
    <h1>Let's work together</h1>
    <h2 class="primary">Connect</h2>
    <div class="row content content2">
        <div class="col-md-6 left">
            <div class="head">Have a project?</div>
            <div class="text2">
                Standing out from the crowd is the only way to grow. We are bold, 
                making the world pay attention to YOU. Here you are the center 
                and we want to <b style="font-weight: 700;">LISTEN to YOU</b>.
            </div>


            <div class="head">Email Us</div>
            <div class="email primary">hello@kilobytetech.com</div>
        </div>
        <div class="col-md-6 right">
            <div class="formInput">
               <input type="text" placeholder="Your Name" [(ngModel)]="contact.name">
           </div>
           <div class="formInput">
               <input type="text" placeholder="Your Email" [(ngModel)]="contact.email">
           </div>
           <div class="formInput">
               <input type="text" placeholder="Tell us about your project" [(ngModel)]="contact.message">
           </div>
           <div class="send primary" (click)="submit()">
               Send
               <img alt='image' src="/assets/images_new/icons/sendArrow.svg" >
           </div>
        </div>
    </div>
    <!-- <div class="row content2">
        <div class="col-md-6 left">
            <div class="head">Have a project?</div>
            <div class="text2">
                Describe your project and leave us your contact info, we'll get back to youwithin 24hours. 
            </div>
        </div>
        <div class="col-md-6 right">
           <div class="formInput">
               <input type="text" placeholder="Your Name" [(ngModel)]="contact.name">
           </div>
           <div class="formInput">
               <input type="text" placeholder="Your Email" [(ngModel)]="contact.email">
           </div>
           <div class="formInput">
               <input type="text" placeholder="Tell us about your project" [(ngModel)]="contact.message">
           </div>
           <div class="send primary" (click)="submit()">
               Send
               <img alt='image' src="/assets/images_new/icons/sendArrow.svg" >
           </div>
        </div>
    </div> -->
    <div class="content3">
        <div class="menu1">
            <div class="head">Say Hello</div>
            <div class="item"><a href="tel:+917895210001">+91 78952 10001</a></div>
            <div class="item"><a href="tel:+917895210001">+91 93199 21551</a></div>
        </div>
        <div class="menu1">
            <div class="head">Join Us</div>
            <div class="item"><a href="mailto:careers@kilobytetech.com">careers@kilobytetech.com</a></div>
        </div>
        <div class="menu1">
            <div class="head">Address</div>
            <div class="item">
                415, MG Road,<br> Gurgaon<br><br>
                Dev Bazaar, Kashipur,<br>Near Jim Corbett National Park<br><br>
                <!-- <a href="tel:+91 7895210001">+91 7895210001</a> 
               <img alt='image' src="/assets/images_new/icons/phone.svg" > -->
            </div>
        </div>
    </div>
    <hr class="hrs">
    <app-footer-new></app-footer-new>
</div>