import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';



@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.scss']
})

export class HeaderNewComponent implements OnInit {

  @Input() textColor: string= '#000';
  showMenu;
  home: boolean= true;
  hide: boolean= false;

  constructor(
    private router: Router,
  ) { 
  }

  ngOnInit() {
    if(this.router.url=='/'){
      this.home= true
    }else{
      this.home= false
    }
    this.router.events.subscribe(()=>{ 
      if(this.router.url=='/'){
        this.home= true
      }else{
        this.home= false
      }
    })
    // this.scrollHead()

  }

  ngAfterViewInit(): void {
    const slider = $("body");
        slider.on('wheel', ((e)=> {    
            if (e.originalEvent.deltaY < 0) {
                this.hide= false
            } else {
                this.hide= true
            }
        }));
  }


  menuOpen() {
    this.showMenu = !this.showMenu;
  }

}
