<div class="header">
    <app-header-new [textColor]="'#111'"></app-header-new>
</div>
<div class="container2">
    <div class="error">
        <div class="c404">404</div>
        <div>
            <img src="/assets/images_new/404/error.gif" alt="">
        </div>
    </div>
    <div class="homePage">
        <div class="np2">The page you are looking for does not exist.</div>
        <div class="hp" >
                <a href="https://kilobytetech.com">Go to homepage</a>
        </div>
    </div>
    <div *ngFor="let img of images;let i=index" class="images img{{i}}">
        <img src="{{img}}" alt="">
    </div>
</div>