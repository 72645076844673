<div class="blogsBody">
    <div class="container2">
        <h2>
            <span class="title">
                From the Blog 
                <span class="count">{{totalBlogs + blogData.length}}</span>
            </span>
        </h2>
        <hr>
        <div class="blogs" style="margin-bottom: 0px !important;">
            <div class="blog" *ngFor="let blog of allBlogs">
                <div class="blogCard" (click)="openBlog(blog)">
                    <div class="imgFrame">
                        <div class="image">
                            <img [src]="blog?.signedUrl[0]?.signedUrl" [alt]="blog?.title">
                        </div>
                    </div>
                    <div class="subTitle">
                        <img alt='image' src="/assets/images_new/blogs/dotBlack.svg" height="10" width="10" >
                        Insights
                    </div>
                    <div class="titleBlog">
                        {{ blog?.title }}
                    </div>
                </div>
            </div>

        </div>

        <div class="blogs">
            <div class="blog" *ngFor="let blog of blogData">
                <div class="blogCard" (click)="openBlogDetail(blog.slug, blog.id)">
                    <div class="imgFrame">
                        <div class="image">
                            <img [src]="" [alt]="blog.title.rendered">
                        </div>
                    </div>
                    <div class="subTitle">
                        <img alt='image' src="/assets/images_new/blogs/dotBlack.svg" height="10" width="10" >
                        Insights
                    </div>
                    <div class="titleBlog">
                        {{ blog.title.rendered }}
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>