<div class="header">
    <app-header-new [textColor]="colorTextHome"></app-header-new>
</div>
<div class="body" #mainBlock [ngStyle]="{backgroundColor: backgroundColorHome, color: colorTextHome}">
    <div class="banner" #banner>
        <!-- <img alt='image' src="/assets/images_new/culture/banner/b1.png" class="b1" > -->
        <img alt='image' src="/assets/images_new/culture/banner/b3.png" class="b2" >
        <img alt='image' src="/assets/images_new/culture/banner/b2.png" class="b3" >
        <img alt='image' src="/assets/images_new/culture/banner/b4.png" class="b4" >
        <img alt='image' src="/assets/images_new/culture/banner/b5.png" class="b5" >
        <img alt='image' src="/assets/images_new/culture/banner/b6.png" class="b6" >
        <span>
            <!-- Painting Vision, Nurturing Culture and Helping people grow together -->
            We are the Square Pegs in the Round Holes
        </span>
    </div>
    <div class="values" #values>
        <img src="/assets/images_new/culture/v1.jpg" alt="v1">
        <p class="desc mbDesc">
            <!-- "We are just a bunch of people from all around the world trying to create something new 
            with the help of design. We are not special, but as a team we beleive we can unlock road to imposible." -->
            We are ambitious and we chase our ambition with a burning passion, moving the world by exploring the uncomfortable. 
            We are creating and exploring ideas which will embed in culture and making profound connections to do so. 
            Our agency is founded on trust, with people who care to keep it.
        </p>
        <section class="marquee-container">
            <div class="marquee" data-speed="-1">
                <div class="inner">
                    <p>
                        <span *ngFor="let value of allValuesImage;let i=index">
                            <img [src]="value" alt="value">
                        </span>
                    </p>
                </div>
            </div>
        </section>
        <div class="value">
            <h3 class="valueTitle">
                Our Values
            </h3>
            <p class="desc">
                Our values are there to remind us of who we are, and what we want to be. 
                They guide everything we do, from our approach to work, to how we treat people. 
                They are intentionally aspirational and aspirationally intentional. 
            </p>
            <p class="desc">
                For us, our values are more than just words. 
                They are ways of living, creating, and doing. 
                Our values run deep through our company culture and have since day one.
            </p>
            
            <div class="img">
                <img alt='image' src="/assets/images_new/culture/prakhar.png" style="width: 280px;" >
                <!-- <img alt='image' src="/assets/images_new/culture/u1.png" > -->
                <img alt='image' src="/assets/images_new/culture/u2.jpg" >
            </div>
        </div>
    </div>
    <div class="ceoDesk" #ceoDesk>
        <div class="ceoBody">
            <img alt='image' src="/assets/images_new/icons/star.svg" class="star star_rotate" >
            <h3>From the desk of CEO</h3>
            <hr>
            <div class="dataCEO">
                <img alt='image' src="/assets/images_new/culture/quote.svg" >
                <div>
                    <p>
                        When we’re not working for our clients, we like to experiment. It helps us think new thoughts, meet new people, create new things, discover beautiful objects, and challenge ourselves.
                    </p>
                    <p>
                        Most things are designed good, but somethings are designed better.   We at Kilobyte Technologies focus on the latter. 
                    </p>
                    <p>
                        Our team comprises of passionate and hardworking indivuduals who try to push their limits each day everyday. I am fortunate to work among these best in the field professionals and lead them for the challenges upcoming and beyond.
                    </p>
                    <div class="name">
                        <div class="datas">
                            <hr>
                            <p class="title">Sumbul Naaz</p>
                            <p class="designation">CEO, Kilobyte Technolgies</p>
                        </div>    
                    </div>
                </div>
            <img alt='image' src="/assets/images_new/culture/quote.svg" class="rotate180" >
            </div>
        </div>
    </div>
    <div class="pillars" #pillars>
        <div class="pillar1s">
            <div>OUR PILLARS</div>
        </div>
        <div class="pillar2" *ngIf="showPillars">
            <div class="pillar4">
                <div class="pillar511"></div>
                <div class="pillar512"></div>
                <div class="pillar513"></div>
                <div class="pillar514"></div>
                <div class="pillar515"></div>
            </div>
            <div class="pillar3">
                <div class="pillarBody">
                    <div class="head">
                        <h2>1</h2>
                        <!-- <h3 class="title">Empathy in action</h3> -->
                        <h3 class="title">Relentlessly Responsible</h3>
                    </div>
                    <p>
                        <!-- It’s a wide world out there, but a beautiful one too, you can do something great alone, you can go farther with a team. We beleive in teamwork and strive for excellence -->
                        We are responsible for the trust you put in us, and we intend to fulfill that responsibility. 
                        We stand with you, no matter the tides. We are building trust amongst our clients and the society. 
                        Success achieved without Honesty, is a success not worth having. 
                        Our motto is transparency, with our clients and with ourselves. Your trust is worth more than anything. 
                    </p>
                </div>
                <div class="pillarBody">
                    <div class="head">
                        <h2>2</h2>
                        <!-- <h3 class="title">All over ego</h3> -->
                        <h3 class="title">Innately Innovative</h3>
                    </div>
                    <p>
                        Conventionality is boring, creativity is exciting. We approach every project from an out-of-the-box mentality, 
                        in the quest to create something unique in this cluttered industry. And we don’t stop unless we achieve that.                     
                    </p>
                </div>
                <div class="pillarBody">
                    <div class="head">
                        <h2>3</h2>
                        <!-- <h3 class="title">Own the work</h3> -->
                        <h3 class="title">Entailing Excellence</h3>
                    </div>
                    <p>
                        Achieving excellence is the endgame. We thrive for excellence, through creativity, 
                        perseverance and hard work Excellence is uncompromisable and we crave it with an uncompromising passion.
                    </p>
                </div>
                <div class="pillarBody">
                    <div class="head">
                        <h2>4</h2>
                        <!-- <h3 class="title">Strive for excellence</h3> -->
                        <h3 class="title">Empathy In Action</h3>
                    </div>
                    <p>
                        We feel what you want to achieve. 
                        We work directly with the end user and understand their pain points first, then craft custom solutions to solve problems. 
                        We want to listen to your ideas and understand what you truly want. Our solutions keep the end user at the center. Always. All ways.                    
                    </p>
                </div>
            </div>
        </div>
        
        <div class="pillar2s">
            <div>OUR PILLARS</div>
        </div>
        <!-- <div class="pillar">
            <h4>OUR PILLARS</h4>
            <h2>Strive for excellence</h2>
            <p>
                It’s a wide world out there, but a beautiful one too, you can do something great alone, you can go farther with a team. We beleive in teamwork and strive for excellence.
            </p>
        </div> -->
    </div>
    <div class="people" #people>
        <div class="peopleBox">
            <h1>
                Behind the Scenes
            </h1>
            <p class="desc">
                <!-- We have a team of strong passionate and hardworking professionals . Now the question arises Who am I? Well I am just a guy who makes sure people receive their pay on time :) -->
                Our Team is a versatile and diverse bunch of people with different outlooks, opinions and backgrounds. From the biggest projects to smallest details, our only stop is perfection.
            </p>
            <p class="descFoot">
                <!-- -CTO -->
            </p>
            <div class="users">
                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img src="/assets/images_new/culture/persons/prakhar.png" alt="prakhar"class="person-img">
                        </div>
                        <div class="name">Prakhar Agrawal</div>
                        <div class="designation">Director</div>
                    </div>
                </div>
                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img src="/assets/images_new/culture/persons/sumbul.png" alt="sumbul"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Sumbul Naaz</div>
                        <div class="designation">CEO</div>
                    </div>
                </div>
                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img alt='mrunmyi' src="../../../assets/images_new/culture/persons/Kajal Tripathi.jpeg"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Kajal Tripathi</div>
                        <div class="designation">HR</div>
                    </div>
                </div>
                
                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img src="../../../assets/images_new/culture/persons/Prakhar Namdev.jpg" alt="Prakhar"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Prakhar Namdev</div>
                        <div class="designation">Back-end Developer</div>
                    </div>
                </div>
                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img alt='Vishal' src="../../../assets/images_new/culture/persons/vishal.png"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Vishal Dodiya</div>
                        <div class="designation">App Developer</div>
                    </div>
                </div>

                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img alt='Vishal Pal' src="../../../assets/images_new/culture/persons/vishal_pal1.jpeg"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Vishal Pal</div>
                        <div class="designation">Front-end Developer</div>
                    </div>
                </div>

                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img src="../../../assets/images_new/culture/persons/Harsh Singh.jpeg" alt="Harsh"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Harsh Singh</div>
                        <div class="designation">Full-Stack Developer</div>
                    </div>
                </div>
                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img src="../../../assets/images_new/culture/persons/sharayu.jpg" alt="Sharayu"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Sharayu Chikhale</div>
                        <div class="designation">Tester</div>
                    </div>
                </div>
                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img alt='Rushikesh' src="../../../assets/images_new/culture/persons/rushikesh.jpg"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Rushikesh</div>
                        <div class="designation">Front-end Developer</div>
                    </div>
                </div>
                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img alt='krishna' src="../../../assets/images_new/culture/persons/Krishna Singh.jpeg"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Krishna Singh</div>
                        <div class="designation">Full-Stack Developer</div>
                    </div>
                </div>
                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img alt='Abhijit' src="../../../assets/images_new/culture/persons/Abhijit.jpg"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Abhijit Kumar</div>
                        <div class="designation">Designer</div>
                    </div>
                </div>
                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img alt='vaidant' src="../../../assets/images_new/culture/persons/vaidant.png"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Vaidant Chhabra</div>
                        <div class="designation">Sales associate</div>
                    </div>
                </div>
                <div class="person">
                    <div class="user">
                        <div class="img">
                            <img src="/assets/images_new/culture/persons/bharat.png" alt="bharat"class="person-img">
                        </div>
                        <div class="overlay"></div>
                        <div class="name">Bharat Agrawal</div>
                        <div class="designation">Finance</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contact container" #contactUs>
        <app-footer-new></app-footer-new>
    </div>
</div>