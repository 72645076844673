import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scribble-t',
  templateUrl: './scribble-t.component.html',
  styleUrls: ['./scribble-t.component.scss']
})
export class ScribbleTComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
