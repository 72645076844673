<div class="main">
    <div class="c1 container2">
        <h1>Fortune projects</h1>
        <h4>Building products of the future</h4>
        <h3>RESEARCH / DESIGN / ART DIRECTION / DEVELOPMENT</h3>
    </div>
    <div class="c2 container2">
        <img alt='image' src="/assets/images_new/case-studies/fortune/f1.png" >
    </div>

    <div class="c3 container2">
        <div class="row c31">
            <div class="col-md-6 left">
                <div class="section">
                    <h2>About</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="section">
                    <h2>Problem</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="section">
                    <h2>Solution</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
            <div class="col-md-6 right">
                <div class="section">
                    <h2>Fortune projects</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <div class="list">
                        <div class="listItem">RESEARCH AND PLANNING</div>
                        <div class="listItem">STRATEGY</div>
                        <div class="listItem">IDEATION</div>
                        <div class="listItem">CONTENT</div>
                        <div class="listItem">BRANDING</div>
                        <div class="listItem">UI/UX DESIGN</div>
                        <div class="listItem">ART DIRECTION</div>
                        <div class="listItem">DEVELOPMENT</div>
                    </div>
                    <div class="visit">
                        Visit site 
                        <img alt='image' src="/assets/images_new/case-studies/hma/arrow-right.svg" height="12" >
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="c4 container2">
        <img alt='image' src="/assets/images_new/case-studies/fortune/f2.svg" >
    </div>
    
    <div class="c5 container2">
        <img alt='image' src="/assets/images_new/case-studies/fortune/f3.svg" >
    </div>

    <div class="c6">
        <div class="container2">
            <div class="c61 row">
                <div class="col-md-6 left" id="sticky">
                    <h2>Strategy</h2>
                    <div class="listItems">Data Analysis</div>
                    <div class="listItems">UX</div>
                    <div class="listItems">Visual Design</div>
                </div>
                <div class="col-md-6 right">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
            <div class="custCards row">
                <div class="col-md-4 cContainer">
                    <div class="cCard">
                        <div class="bg1" style="background-color: #FFDEDE;"></div>
                        <div class="bg2" style="background-color: #FF4747;"></div>
                        <div class="cData" style="color: #fff">
                            <div class="title">
                                Data Analysis
                            </div>
                            <div class="dataList">Identify the competition</div>
                            <div class="dataList">Market analysis</div>
                            <div class="dataList">Users</div>
                            <div class="dataList">Scalability</div>
                            <div class="dataList">Future growth</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 cContainer">
                    <div class="cCard">
                        <div class="bg1" style="background-color: #FFDEDE;"></div>
                        <div class="bg2" style="background-color: #fff;"></div>
                        <div class="cData" style="color: #000">
                            <div class="title" style="border-color: #000;">
                                UX
                            </div>
                            <div class="dataList">UX Reseatch</div>
                            <div class="dataList">Brainstroming</div>
                            <div class="dataList">Ideation</div>
                            <div class="dataList">Content</div>
                            <div class="dataList">Wireframing</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 cContainer">
                    <div class="cCard">
                        <div class="bg1" style="background-color: #FFDEDE;"></div>
                        <div class="bg2" style="background-color: #FF4747;"></div>
                        <div class="cData" style="color: #fff">
                            <div class="title">
                                Visual Design
                            </div>
                            <div class="dataList">Branding</div>
                            <div class="dataList">UI/Visual Design</div>
                            <div class="dataList">Interface Design</div>
                            <div class="dataList">Experience Design</div>
                            <div class="dataList">Micro animations</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="c7">
        <div class="c71 container2">
            <img alt='image' src="/assets/images_new/case-studies/fortune/f4.png" >
            <img alt='image' src="/assets/images_new/case-studies/fortune/f5.png" >
        </div>
    </div>

    <div class="c8">
        <div class="container2">
            <div class="c81 row">
                <div class="col-md-6 left" id="sticky">
                    <h2>The most sustainable design ever built</h2>
                </div>
                <div class="col-md-6 right">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
        </div>
        <img alt='image' src="/assets/images_new/case-studies/fortune/f6.png" class="sixty" >
        <img alt='image' src="/assets/images_new/case-studies/fortune/f7.png" >

    </div>

    <div class="c9">
        <div class="container2">
            <div class="c91 row">
                <div class="col-md-6 left" id="sticky">
                    <h2>UX Research / Ideation</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="col-md-6 right">
                    <img alt='image' src="/assets/images_new/case-studies/fortune/f8.png" >      
                </div>
            </div>
        </div>
    </div>
    <div class="c10">
        <div class="c101">
            <img alt='image' src="/assets/images_new/case-studies/fortune/f9.png" >      
        </div>
        <div class="c101">
            <img alt='image' src="/assets/images_new/case-studies/fortune/f10.png" >      
        </div>
    </div>
</div>

<div class="nextBtn" style="width: 100%;padding-top: 7rem;padding-bottom: 14rem;font-size: 7rem;line-height: 8rem;text-align: center;">
    <span routerLink="/projects/qnvert" style="cursor: pointer;">
        Next
        <img alt='image' src="/assets/images_new/case-studies/arrow.svg" width="100" style="margin-left: 3rem; width: 100px !important;" >
    </span>
</div>