import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scottedil',
  templateUrl: './scottedil.component.html',
  styleUrls: ['./scottedil.component.scss']
})
export class ScottedilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
