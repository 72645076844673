<div class="header">
    <app-header-new [textColor]="colorTextHome"></app-header-new>
</div>
<div id="light" *ngIf="torch">
    <img alt='image' src="/assets/images_new/projects/torchLights.svg" >
</div>
<div class="body" #mainBlock [ngStyle]="{backgroundColor: backgroundColorHome, color: colorTextHome}">
    <div class="banner" id="banner" #banner [ngClass]="mouseInBanner?'mouseIn':''">

        
        <div class="bannerInner">
            <div class="overlay" *ngIf="!torchLight"></div>
            <div class="torch" [ngClass]="torchLight?'top':''" (click)="torchOnOf()">
                <span>Light a torch</span> 
                <img alt='image' class="torchIcon" src="/assets/images_new/projects/torch.svg" width="30" height="28px" > 
                <img alt='image' class="light" *ngIf="torchLight" src="/assets/images_new/projects/torchLight.svg"  height="28px"  style="margin-top: -1px;">
                <div class="light" *ngIf="!torchLight"  style="width: 44px;"></div>
            </div>
            <div class="bodyBanner">
                We create <span class="primary">expereinces</span> that spark a <span class="primary">positive impact</span> on the world, 
                for brands that we admire and products that <span class="primary">inspire</span>
                <img alt='image' src="/assets/images_new/projects/b2.png" class="b2" >
            </div>
            <img alt='image' src="/assets/images_new/projects/b1.png" class="b1" >
            <!-- <div class="cursor" id="cursor"></div> -->
        </div>
    </div>
    
    <div class="project1" #project1 >
        <div class="headProject">
            <img alt='image' src="/assets/images_new/icons/star.svg" class="star" height="95px" width="95px" >
            <h2>Crafting experiences</h2>
            <div class="trailLine">
                <section class="marquee-container">
                    <div class="marquee" data-speed="-1">
                      <div class="inner">
                        <p>
                            <span class="items">Branding</span>
                            <span class="items">Consultancy</span>
                            <span class="items">ux research</span>
                            <span class="items">visual design</span>
                            <span class="items">interaction design</span>
                            <span class="items">frontend development</span>
                            <span class="items">backend development</span>
                        </p>
                      </div>
                    </div>
                </section>
                <!-- <div class="dm1">
                    <span class="items">Branding</span>
                    <span class="items">Consultancy</span>
                    <span class="items">ux research</span>
                    <span class="items">visual design</span>
                    <span class="items">interaction design</span>
                    <span class="items">frontend development</span>
                    <span class="items">backend development</span>
                </div>
                <div class="dm2">
                    <span class="items">Branding</span>
                    <span class="items">Consultancy</span>
                    <span class="items">ux research</span>
                    <span class="items">visual design</span>
                    <span class="items">interaction design</span>
                    <span class="items">frontend development</span>
                    <span class="items">backend development</span> 
                </div>-->
            </div>
        </div>


        <div class="project1Div">
            <div class="sideBar left">
                <div>2021</div>
            </div>
            <div class="data">
                <div class="row">
                    <div class="projCard" routerLink="iptse" style="width: 65%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  
                        <div class="d23 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/iptse.png" >
                            </div>
                        </div>
                        <div class="projName">
                            IPTSE
                        </div>
                        <div class="projType">
                            Website App and Product Design
                        </div>
                    </div>
                    <div class="projCard" routerLink="scottedil" style="width: 28%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  <!--d32 fram of 2x3-->
                        <div class="d32 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/sottedil.png" >
                            </div>
                        </div>                        
                        <div class="projName">
                            SCOTT EDIL
                        </div>
                        <div class="projType">
                            Product Design
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="projCard" routerLink="hexabull" style="width: 46.5%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  
                        <div class="d11 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/hexa.png" >
                            </div>
                        </div>
                        <div class="projName">
                            HEXABULL
                        </div>
                        <div class="projType">
                            Website Design
                        </div>
                    </div>
                    <div class="projCard" routerLink="chargebizz" style="width: 46.5%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  
                        <div class="d23 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/chargebizz.png" >
                            </div>
                        </div>
                        <div class="projName">
                            Chargebizz
                        </div>
                        <div class="projType">
                            Website and Product Design
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    
                    <div class="projCard" routerLink="crate-caffinate" style="width: 28%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  
                        <div class="d23 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/crate.png" >
                            </div>
                        </div>
                        <div class="projName">
                            CRATE CAFFINATE
                        </div>
                        <div class="projType">
                            Web App Design
                        </div>
                    </div>
                    <div class="projCard" routerLink="fortune-projects" style="width: 65%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  
                        <div class="d23 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/fortune.png" >
                            </div>
                        </div>
                        <div class="projName">
                            Fortune Projects
                        </div>
                        <div class="projType">
                            Website Design
                        </div>
                    </div>
                </div>
            </div>
            <div class="sideBar right">
                <div>
                    <!-- AUGUST -->
                    &nbsp;
                </div>
            </div>
        </div>
    </div>

    <div class="project2" #project2 >
        <div class="project2Div">
            <div class="sideBar left">
                <div>2021</div>
            </div>
            <div class="data">
                <!-- <div class="desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla porta semper tincidunt feugiat viverra elit volutpat malesuada. Sagittis mauris, pellentesque viverra quisque a. Eget ultricies sit fringilla in vulputate nunc ut ac ac. Sed accumsan, auctor quisque ultrices eget rhoncus pretium.
                </div> -->
                
                <!-- <div class="row" style="justify-content: center; margin-bottom: 2.4% !important;">
                    <div class="projCard" style="width: 25%;margin: 0 1.2%;">  
                        <div class="d11 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="project2Images[0]" >
                            </div>
                        </div>
                    </div>
                    <div class="projCard" style="width: 25%;margin: 0 1.2%;">  
                        <div class="d11 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="project2Images[1]" >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="justify-content: center; margin-bottom: 1.2%;">
                    <div class="projCard" style="width: 25%;margin: 0 1.2%;">  
                        <div class="d11 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="project2Images[2]" >
                            </div>
                        </div>
                    </div>
                    <div class="projCard" style="width: 25%;margin: 0 1.2%;">  
                        <div class="d11 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="project2Images[3]" >
                            </div>
                        </div>
                    </div>
                </div> -->
                
                <div class="row">
                    <div class="projCard" routerLink="qnvert" style="width: 46.5%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  
                        <div class="d11 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/qnvert.png" >
                            </div>
                        </div>
                        <div class="projName">
                            Qnvert
                        </div>
                        <div class="projType">
                            Web App design
                        </div>
                    </div>
                    <div class="projCard" routerLink="content-talks" style="width: 46.5%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  
                        <div class="d23 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/contentTalk.png" >
                            </div>
                        </div>
                        <div class="projName">
                            CONTENT TALKS
                        </div>
                        <div class="projType">
                            Web App Design
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="projCard" routerLink="finance-tree" style="width: 65%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  
                        <div class="d23 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/financeTree.png" >
                            </div>
                        </div>
                        <div class="projName">
                            THE FINANCE TREE
                        </div>
                        <div class="projType">
                            Web App Design
                        </div>
                    </div>
                    <div class="projCard" routerLink="hma" style="width: 28%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  <!--d32 fram of 2x3-->
                        <div class="d32 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/hma.png" >
                            </div>
                        </div>                        
                        <div class="projName">
                            HMA
                        </div>
                        <div class="projType">
                            Web App Design
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="projCard" routerLink="dizaina" style="width: 28%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  
                        <div class="d11 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/dizania.png" >
                            </div>
                        </div>
                        <div class="projName">
                            DIZANIA
                        </div>
                        <div class="projType">
                            Website Design
                        </div>
                    </div>
                    <div class="projCard" routerLink="sanron" style="width: 65%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  <!--d32 fram of 2x3-->
                        <div class="d23 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/sanron.png" >
                            </div>
                        </div>                        
                        <div class="projName">
                            SANRON
                        </div>
                        <div class="projType">
                            Website Design
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="projCard" routerLink="scribble-t" style="width: 46.5%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  
                        <div class="d11 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/qnvert.png" >
                            </div>
                        </div>
                        <div class="projName">
                            SCRIBBLE T
                        </div>
                        <div class="projType">
                            Web App design
                        </div>
                    </div>
                </div>

<!-- 
                <div class="row">
                    <div class="projCard" routerLink="dizaina" style="width: 28%;" (mouseover)="showCSP(true)" (mouseout)="showCSP(false)">  
                        <div class="d11 divRatio"> 
                            <div class="contain">
                                <img alt='image' src="/assets/images_new/projects/qnvert.png" >
                            </div>
                        </div>
                        <div class="projName">
                            SCRIBBLE T
                        </div>
                        <div class="projType">
                            Website Design
                        </div>
                    </div>
                </div> -->

            </div>
            <div class="sideBar right">
                <div>
                    <!-- JULY -->
                    &nbsp;
                </div>
            </div>
        </div>
    </div>
    <hr class="line1">

    <div class="contact container" #contactUs>
        <app-footer-new></app-footer-new>
    </div>
</div>