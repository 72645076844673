<div class="main">
    <div class="c1 container2">
        <h1>HEXABULL</h1>
        <h4>Number one app for Ca’s </h4>
    </div>
    <div class="c2 container2">
        <img alt='image' src="/assets/images_new/case-studies/hexabull/h1.png" >
    </div>
    
    <div class="c3 container2">
        <div class="row c31">
            <div class="col-md-6 left">
                <div class="section">
                    <h2>About</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="section">
                    <h2>Problem</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="section">
                    <h2>Solution</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
            <div class="col-md-6 right">
                <div class="section">
                    <h2>Hexabull</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <div class="list">
                        <div class="listItem">RESEARCH AND PLANNING</div>
                        <div class="listItem">STRATEGY</div>
                        <div class="listItem">IDEATION</div>
                        <div class="listItem">CONTENT</div>
                        <div class="listItem">BRANDING</div>
                        <div class="listItem">UI/UX DESIGN</div>
                        <div class="listItem">ART DIRECTION</div>
                        <div class="listItem">DEVELOPMENT</div>
                    </div>
                    <div class="visit">
                        Visit site 
                        <img alt='image' src="/assets/images_new/case-studies/hma/arrow-right.svg" height="12" >
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="c4 container2">
        <div class="right row">
            <div class="col-md-6 c411">
                <img alt='image' src="/assets/images_new/case-studies/hexabull/h2.svg" >
            </div>
            <div class="col-md-6 c412">
                <img alt='image' src="/assets/images_new/case-studies/hexabull/h3.svg" >
            </div>
        </div>
        <div class="c42">
            <img alt='image' src="/assets/images_new/case-studies/hexabull/h4.png" >
            <img alt='image' src="/assets/images_new/case-studies/hexabull/h5.png" >
        </div>
    </div>

    <div class="c5">
        <div class="boxx">
            <h2>Branding</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
            </p>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
            </p>
        </div>
    </div>

    <div class="c6">
        <img alt='image' src="/assets/images_new/case-studies/hexabull/h6.png" >
        <div class="half">
            <img alt='image' src="/assets/images_new/case-studies/hexabull/h7.png" class="c61" >
            <img alt='image' src="/assets/images_new/case-studies/hexabull/h8.png" class="c62" >
        </div>
    </div>

    <div class="c7 container2">
        <div class="c71 row">
            <div class="col-md-6 left" id="sticky">
                <h2>The most flexible dashboard ever built</h2>
            </div>
            <div class="col-md-6 right">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>
            </div>
        </div>
        <img alt='image' src="/assets/images_new/case-studies/hexabull/h9.png" class="c62" >
    </div>

    <div class="c8">
        <div class="container2">
            <div class="c81 row">
                <div class="col-md-6 left" id="sticky">
                    <h2>Strategy</h2>
                    <div class="listItems">Data Analysis</div>
                    <div class="listItems">UX</div>
                    <div class="listItems">Visual Design</div>
                </div>
                <div class="col-md-6 right">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
            <div class="custCards row">
                <div class="col-md-4 cContainer">
                    <div class="cCard">
                        <div class="bg1" style="background-color: #FFDFDA;"></div>
                        <div class="bg2" style="background-color: #ED523A;"></div>
                        <div class="cData" style="color: #fff">
                            <div class="title">
                                Data Analysis
                            </div>
                            <div class="dataList">Identify the competition</div>
                            <div class="dataList">Market analysis</div>
                            <div class="dataList">Users</div>
                            <div class="dataList">Scalability</div>
                            <div class="dataList">Future growth</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 cContainer">
                    <div class="cCard">
                        <div class="bg1" style="background-color: #FFDFDA;"></div>
                        <div class="bg2" style="background-color: #fff;"></div>
                        <div class="cData" style="color: #000">
                            <div class="title" style="border-color: #000;">
                                UX
                            </div>
                            <div class="dataList">UX Reseatch</div>
                            <div class="dataList">Brainstroming</div>
                            <div class="dataList">Ideation</div>
                            <div class="dataList">Content</div>
                            <div class="dataList">Wireframing</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 cContainer">
                    <div class="cCard">
                        <div class="bg1" style="background-color: #FFDFDA;"></div>
                        <div class="bg2" style="background-color: #ED523A;"></div>
                        <div class="cData" style="color: #fff">
                            <div class="title">
                                Visual Design
                            </div>
                            <div class="dataList">Branding</div>
                            <div class="dataList">UI/Visual Design</div>
                            <div class="dataList">Interface Design</div>
                            <div class="dataList">Experience Design</div>
                            <div class="dataList">Micro animations</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="c9">
        <img alt='image' src="/assets/images_new/case-studies/hexabull/h10.png" class="c62" >
    </div>

    <div class="c10 container2">
        <div class="boxx">
            <h2>Visual Design</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
            </p>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
            </p>
        </div>
    </div>

    <div class="c11 container">
        <img alt='image' src="/assets/images_new/case-studies/hexabull/h11.png" class="c62" >
    </div>

    <div class="c12 container2">
        <div class="c121 row">
            <div class="col-md-6 left" id="sticky">
                <h2>Visual Design</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>
            </div>
            <div class="col-md-6 right">
                <div class="width70 custCard2">
                    <img alt='image' src="/assets/images_new/case-studies/hexabull/h12.png" class="c62" >
                </div>
                <div class="width70 custCard2">
                    <img alt='image' src="/assets/images_new/case-studies/hexabull/h13.png" class="c62" >
                </div>
                <div class="width70 custCard2">
                    <img alt='image' src="/assets/images_new/case-studies/hexabull/h14.png" class="c62" >
                </div>
                <div class="custCard2">
                    <img alt='image' src="/assets/images_new/case-studies/hexabull/h15.png" class="c62" >
                </div>
            </div>
        </div>
    </div>

    <div class="c13 container2">
        <div class="c131 row">
            <div class="col-md-6 left" id="sticky">
                <h2>The most flexible dashboard ever built</h2>
            </div>
            <div class="col-md-6 right">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>
            </div>
        </div>
        <div class="row c132">
            <div class="col-md-6">
                <div class="left">
                    <h2>VISUAL DESIGN</h2>
                    <div class="card3 bgr">
                        <img alt='image' src="/assets/images_new/case-studies/hexabull/h16.png" >
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
            <div class="col-md-6 rr">
                <div class="right">
                    <h2>VISUAL DESIGN</h2>
                    <div class="card3 bgg">
                        <img alt='image' src="/assets/images_new/case-studies/hexabull/h17.png" >
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
        </div>
        <div class="row c132">
            <div class="col-md-6">
                <div class="left">
                    <h2>VISUAL DESIGN</h2>
                    <div class="card3 bgg">
                        <img alt='image' src="/assets/images_new/case-studies/hexabull/h18.png" >
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
            <div class="col-md-6 rr">
                <div class="right">
                    <h2>VISUAL DESIGN</h2>
                    <div class="card3 bgr">
                        <img alt='image' src="/assets/images_new/case-studies/hexabull/h19.png" >
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="c14">
        <img alt='image' src="/assets/images_new/case-studies/hexabull/h20.png"  >
        <img alt='image' src="/assets/images_new/case-studies/hexabull/h21.png"  >
    </div>
</div>

<div class="nextBtn" style="width: 100%;padding-top: 7rem;padding-bottom: 14rem;font-size: 7rem;line-height: 8rem;text-align: center;">
    <span routerLink="/projects/chargebizz" style="cursor: pointer;">
        Next
        <img alt='image' src="/assets/images_new/case-studies/arrow.svg" width="100" style="margin-left: 3rem; width: 100px !important;" >
    </span>
</div>