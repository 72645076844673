import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crate-caffinate',
  templateUrl: './crate-caffinate.component.html',
  styleUrls: ['./crate-caffinate.component.scss']
})
export class CrateCaffinateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
