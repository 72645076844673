import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-project-home',
  templateUrl: './project-home.component.html',
  styleUrls: ['./project-home.component.scss']
})
export class ProjectHomeComponent implements OnInit {

  torchLight: boolean= false;
  torch: boolean= false;
  mouseInBanner: boolean= true
  showCSPointer: boolean= false

  constructor() { }


  ngOnInit(): void {
    document.addEventListener('mousemove',this.updateMouseCursor)
    document.addEventListener('touchmove',this.updateMouseCursor)
  }
  updateMouseCursor(e: any){
    let el= document.getElementById('light');
    if(el){
      el.style.top= (e.pageY-230)+'px';
      el.style.left= (e.pageX-230)+'px';
    }
  }

  torchOnOf(){
    this.torchLight=!this.torchLight
    if(!this.torchLight){
      this.torch= false
    }else{
      this.torch= true
    }
  }

  

  // background color switch
  scrollPosition: number= 0;

  backgroundColorHome: string='#111';
  colorTextHome: string= '#fff';

  @ViewChild("mainBlock") mainDiv: ElementRef;
  @ViewChild("banner") bannerDiv: ElementRef;
  @ViewChild("project1") project1Div: ElementRef;
  @ViewChild("project2") project2Div: ElementRef;
  @ViewChild("contactUs") contactDiv: ElementRef;

  // scrooling position 
  ngAfterViewInit(): void {
    let dialogElement = this.mainDiv.nativeElement as HTMLElement;
      dialogElement.onscroll = () => {
          this.scrollPosition= dialogElement.scrollTop;
            this.changeBgColor();
      }
    this.marqueeScroll()
  }

  changeBgColor(){
    // console.log(this.bannerDiv)
    var bannerHeight= Math.max(this.bannerDiv.nativeElement.scrollHeight, this.bannerDiv.nativeElement.offsetHeight, this.bannerDiv.nativeElement.clientHeight);
    var project1Height= Math.max(this.project1Div.nativeElement.scrollHeight, this.project1Div.nativeElement.offsetHeight, this.project1Div.nativeElement.clientHeight);
    var project2Height= Math.max(this.project2Div.nativeElement.scrollHeight, this.project2Div.nativeElement.offsetHeight, this.project2Div.nativeElement.clientHeight);
    var contactUsHeight= Math.max(this.contactDiv.nativeElement.scrollHeight, this.contactDiv.nativeElement.offsetHeight, this.contactDiv.nativeElement.clientHeight);

    if(this.scrollPosition<this.getColorChangeHeight(bannerHeight) && this.scrollPosition>0){
      this.torch= true
      this.backgroundColorHome= "#000"
      this.colorTextHome= "#fff"
    }
    if(this.scrollPosition<this.getColorChangeHeight(project1Height)+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(bannerHeight)){
      this.torch= false
      // this.backgroundColorHome= '#f9f9f9'
      this.backgroundColorHome= '#111'
      this.colorTextHome= '#fff'
      // this.colorTextHome= '#111'
    }
    if(this.scrollPosition<this.getColorChangeHeight(project2Height)+project1Height+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(project1Height)+bannerHeight){
      // this.backgroundColorHome= '#2a2a2a'
      this.backgroundColorHome= '#111'
      this.colorTextHome= '#fff'
    }
    if(this.scrollPosition<this.getColorChangeHeight(contactUsHeight)+project2Height+project1Height+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(project2Height)+project1Height+bannerHeight){
      this.backgroundColorHome= '#ffffff'
      this.colorTextHome= '#000'
    }  
  }
  getColorChangeHeight(n: number){
    return n - (this.mainDiv.nativeElement.clientHeight * 0.4)
    // return n*0.6
  }

  marqueeScroll() {
    const marquees = document.querySelectorAll("div.marquee");
    marquees.forEach(function (element: any) {
      let tick = 1;
      let value = element.dataset.speed;
      element.innerHTML += element.innerHTML;
      element.innerHTML += element.innerHTML;

      const innerTags = element.querySelectorAll("div.inner");
      innerTags.forEach((inner, index) => {
        inner.style.left = inner.offsetWidth * index + "px";
      });

      const ticker = function () {
        tick += parseInt(value);
        //element.innerHTML = tick;
        //element.style.left = tick + "px";
        innerTags.forEach((inner, index) => {
          // let width = inner.offsetWidth;
          let width = 1208;
          let normalizedMarqueeX = ((tick % width) + width) % width;
          let pos = width * (index - 1) + normalizedMarqueeX;

          inner.style.left = pos + "px";
        });
        requestAnimationFrame(ticker);
      };
      ticker();
    });
  }


  showCSP(opt){
    this.showCSPointer=opt

  }

}
