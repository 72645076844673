<div class="main">
    <div class="c1 container2">
        <h1>HIP</h1>
        <h4>Insurance At Click 1</h4>
    </div>

    <div class="c2 container2">
        <img alt='image' src="/assets/images_new/case-studies/HIP/img1.png">
    </div>


    <div class="c3 container2">
        <div class="row c31">
            <div class="col-md-6 left">
                <div class="section">
                    <h2>About</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>

                </div>
                <div class="section">
                    <h2>Problem</h2>
                    <p>
                        Competing with the insurance aggregator giants in India, the offline agents faced a lot of
                        difficulty in getting the right information at one place. They often lost business to quick and
                        automated reports of big giants.
                    </p>

                </div>
                <div class="section">
                    <h2>Solution</h2>
                    <p>
                        Revolutionising Health Insurance Industry by bringing transparency and convenience to every
                        agent. The system enables lakhs of agents pan-india, to help bring insurance to their customers
                        seamlessly. We help them generate customised proposals for their customers in less than 30 secs.
                    </p>
                </div>
            </div>
            <div class="col-md-6 right">
                <div class="section">
                    <h2>HIP -</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>

                </div>
            </div>
        </div>
    </div>

    <div class="c4 container2">
        <div class="right row">
            <div class="col-md-12 c411">
                <img alt='image' src="/assets/images_new/case-studies/HIP/img2.png">
            </div>
        </div>
    </div>
</div>