import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hexabull',
  templateUrl: './hexabull.component.html',
  styleUrls: ['./hexabull.component.scss']
})
export class HexabullComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
