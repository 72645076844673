import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogs-new',
  templateUrl: './blogs-new.component.html',
  styleUrls: ['./blogs-new.component.scss']
})
export class BlogsNewComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
}
