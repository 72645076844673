import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/utils/services/common/common.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit {
  blogData: any = [];
  openDetailBlog: any;

  constructor(
    private common: CommonService,
    private router: Router
  ) { 
    this.getBlogs()
    this.blogs();
  }

  allBlogs: Array<any>= [];
  totalBlogs: number= 0;

  pagination ={
    page: 0,
    limit: 100
  };

  ngOnInit(): void {
  }


  getBlogs() {
    this.common.getBlogs({...this.pagination}).subscribe(res => {
      this.allBlogs = res['blogs'];
      this.totalBlogs= res['totalBlogs']
    })
  }

  openBlog(blog:any){
    this.router.navigate([`/blogs/${blog.urlTitle}`])
  }

  blogs() {
    this.common.blogs().subscribe(res => {
    console.log(res);
    this.blogData = res;
    })
  }

  openBlogDetail(blog : any, id : any){
    sessionStorage.setItem('blogID', id);
    this.router.navigate([`/blogs/${blog}`]);
  }
}
