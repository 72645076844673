<div class="main">
    <div class="c1 container2">
        <h1>Chargebizz</h1>
        <h4>Say Hello to safe environment <img alt='image' src="/assets/images_new/case-studies/chargebizz/palm_tree.svg" ></h4>
        <h3>RESEARCH / DESIGN / ART DIRECTION / DEVELOPMENT</h3>
    </div>

    <div class="c2 container2">
        <img alt='image' src="/assets/images_new/case-studies/chargebizz/banner.png" >
    </div>

    <div class="c3 container2">
        <div class="row c31">
            <div class="col-md-6 left">
                <div class="section">
                    <h2>About</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="section">
                    <h2>Problem</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="section">
                    <h2>Solution</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
            <div class="col-md-6 right">
                <div class="section">
                    <h2>Chargebizz -</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <div class="list">
                        <div class="listItem">RESEARCH AND PLANNING</div>
                        <div class="listItem">STRATEGY</div>
                        <div class="listItem">IDEATION</div>
                        <div class="listItem">CONTENT</div>
                        <div class="listItem">BRANDING</div>
                        <div class="listItem">UI/UX DESIGN</div>
                        <div class="listItem">ART DIRECTION</div>
                        <div class="listItem">DEVELOPMENT</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class=" c4 container2">
        <img alt='image' src="/assets/images_new/case-studies/chargebizz/c1.png" >
    </div>

    <div class="c5">
        <div class="container2">
            <div class="row c51">
                <h2 class="col-12">
                    STRATEGY    
                    <img alt='image' src="/assets/images_new/case-studies/chargebizz/doubleArrow.svg" >
                </h2>
                <div class="col-md-6">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
            <div class="c52">
                <img alt='image' src="/assets/images_new/case-studies/chargebizz/c9.png" >
            </div>
            <div class="c53">
                Sitemap/User Flow
            </div>
            <div class="c54">
                <img alt='image' src="/assets/images_new/case-studies/chargebizz/c2.png" >
            </div>
        </div>
    </div>

    
    <div class="c6 container2">
        <img alt='image' src="/assets/images_new/case-studies/chargebizz/c3.png" >
    </div>
    
    <div class="c7">
        <div class="container2">
            <h3>High Fidelity Wireframes</h3>
            <img alt='image' src="/assets/images_new/case-studies/chargebizz/c4.png" >
        </div>
    </div>

    <div class="c8 container2">
        <div class="c81 row">
            <div class="col-md-6">
                <h2>DESIGNOLOGY</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>
            </div>
        </div>
        <div class="c82">
            <img alt='image' src="/assets/images_new/case-studies/chargebizz/c5.png" >
        </div>
        <div class="c83 row">
            <div class="col-md-6 left">
                <div class="c831">APP SCREENS</div>
                <h2>UX Research / Ideation</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>
            </div>
            <div class="col-md-6 right">
                <img alt='image' src="/assets/images_new/case-studies/chargebizz/c6.png" >
            </div>
            <div class="col-12">
                <div class="row">
                    <div class="col-md-5 center">
                        <h2>UX Research / Ideation</h2>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                        </p>
                        <div class="c832">
                            <img alt='image' src="/assets/images_new/case-studies/chargebizz/c62.png" >
                            <span class="c832x"><span class="blue">01 </span>hrs : <span class="blue">30 </span>mins</span>
                            <img alt='image' src="/assets/images_new/case-studies/chargebizz/c99.png" class="c832y">
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-md-7 center">
                <h2>UX Research / Ideation</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                    ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                </p>
                <div class="c833">
                    <img alt='image' src="/assets/images_new/case-studies/chargebizz/c63.png" >
                    <!-- <img alt='image' src="/assets/images_new/case-studies/chargebizz/car1.png"  class="cars car1"> -->
                    <!-- <img alt='image' src="/assets/images_new/case-studies/chargebizz/car2.png"  class="cars car2">
                    <img alt='image' src="/assets/images_new/case-studies/chargebizz/car1.png"  class="cars car3">
                    <img alt='image' src="/assets/images_new/case-studies/chargebizz/car2.png"  class="cars car4"> -->
                </div>
            </div>
        </div>
    </div>

    <div class="c9">
        <div class="container2">
            <div class="c91 row">
                <div class="col-md-6 left">
                    <h2>LANDING PAGE</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="col-md-6">
                    <img alt='image' src="/assets/images_new/case-studies/chargebizz/c7.png" >
                </div>
            </div>
        </div>
    </div>

    <div class="c10">
        <div class="container2">
            <div class="row">
                <div class="col-md-6">
                    <div class="c101">DASHBOARD</div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
        </div>
        <div class="c102">
            <drag-scroll [scrollbar-hidden]="true" #nav>
                <div class="sliderImage" drag-scroll-item>
                    <img alt='image' src="/assets/images_new/case-studies/chargebizz/slider-1.png" >
                </div>
                <div class="sliderImage" drag-scroll-item>
                    <img alt='image' src="/assets/images_new/case-studies/chargebizz/slider-1.png" >
                </div>
                <div class="sliderImage" drag-scroll-item>
                    <img alt='image' src="/assets/images_new/case-studies/chargebizz/slider-1.png" >
                </div>
            </drag-scroll>
            <div class="dragScrollBtn">
                <button (click)="moveTo(0)"></button>
                <button (click)="moveTo(1)"></button>
                <button (click)="moveTo(2)"></button>
            </div>
        </div>
    </div>

    <div class="c11">
        <div class="container2">
            <div class="row">
                <div class="col-md-6">
                    <div class="c101">ALL APP SCREENS</div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
        </div>
        <img alt='image' src="/assets/images_new/case-studies/chargebizz/c8.png" >
    </div>

</div>

<div class="nextBtn" style="width: 100%;padding-top: 7rem;padding-bottom: 14rem;font-size: 7rem;line-height: 8rem;text-align: center;">
    <span routerLink="/projects/crate-caffinate" style="cursor: pointer;">
        Next
        <img alt='image' src="/assets/images_new/case-studies/arrow.svg" width="100" style="margin-left: 3rem; width: 100px !important;" >
    </span>
</div>