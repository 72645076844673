import { Component, OnInit } from '@angular/core';
import { validationError, patternValidator } from '../../utils/common/common';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarService } from 'src/app/utils/services/snackbar/snackbar.service';
import { CommonService } from 'src/app/utils/services/common/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  loading: boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public common: CommonService,
    private msg: SnackbarService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
    });
  }


  resetPassword(data) {
    if (this.form.invalid) {
      validationError(this.form);
    }
    else {
      this.loading = true;
      this.common.forgotPassword(data).subscribe(res => {
        this.loading = false;
        this.msg.openSnackBar('Reset mail has been sent to registered mail.');
      }, (err) => {
        this.loading = false;
        this.msg.openSnackBar(err);
      })
    }

  }

}
