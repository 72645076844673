import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/utils/services/common/common.service';
import { SnackbarService } from 'src/app/utils/services/snackbar/snackbar.service';
import { validationError, patternValidator } from '../../utils/common/common';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  token: string;
  loading: boolean;
  showPass: boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    public common: CommonService,
    private msg: SnackbarService,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(res => {
      this.token = res['resetToken'];
      // this.verifyToken(this.token);
    })
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      newPassword: ['', [Validators.required]]
    });
  }

  // verifyToken(token) {
  //   this.common.verifyToken(token).subscribe(res => {
  //     console.log(res);
  //   }, (err) => {
  //     this.msg.openSnackBar(err);
  //   })
  // }

  showPassword(val) {
    this.showPass = val;
  }

  resetPassword(data) {
    if (this.form.invalid) {
      validationError(this.form);
    }
    else {
      this.loading = true;
      this.common.resetPassword(data, { resetToken: this.token }).subscribe(res => {
        this.loading = false;
        this.msg.openSnackBar('Password is changed successfully. Try login');
        this.router.navigate(['/auth/login']);
      }, (err) => {
        this.loading = false;
        this.msg.openSnackBar(err);
      })
    }

  }

}
