<div class="flex-center auth-page">
  <div class="white-div">
    <div class="row fh">
      <div class="col-md-6">
        <div>
          <div class="logo cursor" routerLink="/">
            <img alt='image' src="../../../assets/logo.svg" />
          </div>
        </div>
        <div class="text-div">
          <h2>Lorem ipsum, or lipsum as it is sometimes known</h2>
          <p class="mt-3">Lorem ipsum, or lipsum as it is sometimes known, is dummy text Lorem ipsum, or lipsum as it is
            sometimes known, is dummy text</p>
          <div class="points">

          </div>
        </div>
      </div>
      <div class="col-md-6 form-col">
        <div class="form-div">
          <div class="mb-4">
            <h5>Login</h5>
          </div>
          <form [formGroup]="form">
            <div class="input-div">
              <p class="label">Email</p>
              <input formControlName="email" placeholder="">
              <div class="err-div">
                <span
                  *ngIf="form.controls['email'].errors?.required && (form.controls['email']?.dirty || form.controls['email']?.touched)">
                  Email is required
                </span>
                <span
                  *ngIf="!form.controls['email'].errors?.required && (form.controls['email']?.invalid && form.controls['email']?.touched)">
                  Email is not valid
                </span>
              </div>
            </div>
            <div class="password-div">
              <p class="label">Password</p>
              <div class="password-input">
                <input formControlName="password" [type]="!showPass?'text':'password'">
                <i class="material-icons cursor" *ngIf="!showPass" (click)="showPassword(true)">
                  visibility_off
                </i>
                <i class="material-icons cursor" *ngIf="showPass" (click)="showPassword(false)">
                  visibility
                </i>
              </div>
              <div class="err-div">
                <span
                  *ngIf="form.controls['password'].errors?.required && (form.controls['password']?.dirty || form.controls['password']?.touched)">
                  Password is required
                </span>
              </div>
            </div>
            <div class="mt-4 text-right">
              <button class="btn primary-btn fw" (click)="login(form?.value)">
                <span *ngIf="!loading">Login</span>
                <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
