<div class="blogsBody">
    <div class="container2">
        <hr class="topLine">
        <div class="blogMiscData">
            <div class="back pointer" routerLink="/blogs">
                <img alt='image' src="/assets/images_new/blogs/arrowLeft.svg" height="10px" >
                Back to Blogs
            </div>
            <div>
                <div class="title">
                    Kilobyte <span class="primary">Technologies</span>
                </div>
                <div class="desc" *ngIf="!postDetails?.title.rendered">
                    {{ postDetails?.title }} - {{ postDetails?.when | date: 'mediumDate' }}
                </div>
                <div class="desc" *ngIf="postDetails?.title.rendered">
                    {{ postDetails?.title.rendered }} - {{ postDetails?.date | date: 'mediumDate' }}
                </div>
                <div class="button">
                    <button class="btn">Subscribe</button>
                </div>
                <hr class="topLine">

            </div>

        </div>
        <div class="blogDataBody">
            <div class="headBlog">
                <div class="left">
                    <div class="title"*ngIf="!postDetails?.title.rendered">
                        {{ postDetails?.title }}
                    </div>
                    <div class="title" *ngIf="postDetails?.title.rendered">
                        {{ postDetails?.title.rendered }}
                    </div>
                    <div class="size" *ngIf="postDetails?.howBig">
                        {{ postDetails?.howBig }}
                    </div>
                    <div class="user">
                        <img alt='image' src="/assets/images_new/blogs/user.png" >
                        <div>
                            <div class="name" *ngIf="postDetails?.who">{{postDetails?.who}}</div>
                            <div class="name" *ngIf="postDetails?._links">{{authorName}}</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="right" *ngIf="postDetails?.signedUrl[0].signedUrl">
                    <img alt='image' [src]="postDetails?.signedUrl[0].signedUrl" >
                </div> -->
            </div>
            <hr class="lineHr">
            <div id="htmlContent">
            
            </div>
        </div>
    </div>
    <div class="explore">
        <div class="container2">
            <h3>Hey you made it this far!</h3>
            <h1>Explore more.</h1>
            <p>
                <span routerLink="/blogs">
                    View all
                    <img alt='image' src="/assets/images_new/blogs/arrow.svg" >
                </span>
            </p>
            <div class="blogList">
                <div class="blog" *ngFor="let blog of allBlogs">
                    <div class="blogCard" (click)="openBlog(blog)">
                        <div class="imgFrame">
                            <div class="image">
                                <img [src]="blog?.signedUrl[0].signedUrl" [alt]="blog?.title">
                            </div>
                        </div>
                        <div class="subTitle">
                            <img alt='image' src="/assets/images_new/blogs/dotBlack.svg" height="10" width="10" >
                            Insights
                        </div>
                        <div class="titleBlog">
                            {{ blog?.title }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>