<header class="container2" [ngStyle]="{color: textColor}" [ngClass]="{'hideHeader': hide}">
    <div class="logo" routerLink="/">
        <!-- <div class="star" *ngIf="home">
            <img alt='image' src="/assets/images_new/icons/star.svg" class="star_rotate" height="16px" width="16px" >
        </div>
        <div class="title" *ngIf="home">
            KILOBYTE<br>TECHNOLOGIES
        </div> -->
        <!-- <span class="title2" *ngIf="!home">KILOBYTE <span class="primary">TECHNOLOGIES</span></span> -->
        <app-logo-kilobyte [fontSize]="34"></app-logo-kilobyte>
        <!-- <span class="title2 companyLogo">kilobyte<span class="primary">.</span></span> -->
    </div>
    <div class="menu bgGrey">
        <!-- <div class="menuItem">
            <span class="pointer" routerLink="/projects">Case Studies</span>
        </div>
        <div class="menuItem">
            <img alt='image' src="/assets/images_new/icons/star.svg" class="star_rotate" height="16px" width="16px" >
            <span class="primary pointer" routerLink="/contact-us">Contact us</span>
        </div> -->
        <!-- <img alt='image' src="/assets/images_new/icons/menu.svg" class="pointer" width="21px" (click)="menuOpen()" > -->
        <div class="pointer" style="font-size: 20px;">
            <!-- <img alt='image' src="/assets/images_new/menuicon.svg" width="28" (click)="menuOpen()"> -->

            <svg width="28" height="11" viewBox="0 0 28 11" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="menuOpen()">
                <line y1="1" x2="28" y2="1" stroke="white" stroke-width="2" />
                <path d="M12 10L28 10" stroke="white" stroke-width="2" />
            </svg>

            <!-- <i class="fa fa-bars" (click)="menuOpen()"></i> -->
        </div>
    </div>
</header>

<div class="menu2" [ngClass]="{'show-menu': showMenu}">
    <app-side-menu (closeMenu)="menuOpen()" [showMenu]="showMenu">Loading...</app-side-menu>
</div>