import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sanron',
  templateUrl: './sanron.component.html',
  styleUrls: ['./sanron.component.scss']
})
export class SanronComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
