import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  images: Array<any>=[
    '/assets/images_new/404/1.png',
    '/assets/images_new/404/2.png',
    '/assets/images_new/404/3.png',
    '/assets/images_new/404/4.png',
    '/assets/images_new/404/5.png',
    '/assets/images_new/404/6.png',
  ]

}
