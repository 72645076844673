import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'app-chargebizz',
  templateUrl: './chargebizz.component.html',
  styleUrls: ['./chargebizz.component.scss']
})
export class ChargebizzComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @ViewChild('nav', {read: DragScrollComponent}) ds: DragScrollComponent;

  moveTo(index) {
    this.ds.moveTo(index);
  }


}
