import { AbstractControl, ValidatorFn } from "@angular/forms";

export function validationError(form) {
    Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        control.markAsTouched({ onlySelf: true });
        if (control.controls) {
            validationError(control);
        }
    });
}

export function patternValidator(regexp: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const value = control.value;
        if (value === '') {
            return null;
        }
        return !regexp.test(value) ? { 'patternInvalid': { regexp } } : null;
    };
}

export function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
            delete obj[propName];
        }
        if (typeof obj[propName] == 'object' && !(obj[propName] instanceof Date)) {
            obj[propName] = clean(obj[propName]);
            if (Object.keys(obj[propName]).length == 0) {
                delete obj[propName];
            }
        }
    }

    return obj;
}

export function fillDataInForm(form, data) {
    for (let control of Object.keys(form.controls)) {
        if (data[control]) {
            form.controls[control].setValue(data[control]);
        }
    }
}

export function formatImages(images) {
    if (images && images.length > 0) {
        let formattedIamges = [];
        formattedIamges = images.map(element => {
            return element.url
        })
        return formattedIamges;
    }
    else {
        return [];
    }
}

export function fillImages(control, images) {
    let formattedIamges = [];
    formattedIamges = images.map(element => {
        return {
            url: element.image,
            signedUrl: element.signedUrl
        }
    });
    control.setValue(formattedIamges);
}

export const marqueeColors = {
    first: [
        'white',
        '#FE5137',
        'white',
        '#029BF0'
    ],
    second: [
        'white',
        '#00C672',
        'white',
        '#FEC028'
    ],
}

export const websiteColors = [
    '#FE5137',
    '#029BF0',
    '#00C672',
    '#FEC028'
];

export const color = {
    redColor: '#FE5137',
    blueColor: '#029BF0',
    greenColor: '#00C672',
    yellowColor: '#FEC028'
};