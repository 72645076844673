import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-iptse',
  templateUrl: './iptse.component.html',
  styleUrls: ['./iptse.component.scss']
})
export class IptseComponent implements OnInit {


  ngOnInit() { 
  }

  ngAfterViewChecked() {        
  } 
}
