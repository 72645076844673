import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.scss']
})
export class FooterNewComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  home: boolean= true;

  ngOnInit() {
    if(this.router.url=='/'){
      this.home= true
    }else{
      this.home= false
    }
    this.router.events.subscribe(()=>{ 
      if(this.router.url=='/' || this.router.url=='/culture'){
        this.home= true
      }else{
        this.home= false
      }
    })
  }
}
