import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { patternValidator, validationError } from 'src/app/utils/common/common';
import { CommonService } from 'src/app/utils/services/common/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showPass = true;
  form;
  loading: boolean;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private common: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: [
        '',
        [Validators.required]
      ],
    });
  }


  showPassword(val) {
    this.showPass = val;
  }

  login(data) {
    if (this.form.invalid) {
      validationError(this.form);
    }
    else {
      this.loading = true;
      this.common.login(data).subscribe(res => {
        console.log(res);
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('token', res['accessToken']);
          this.router.navigate(['/admin']);
        }
      }, (err) => {
        this.loading = false;
      })
    }

  }

}
