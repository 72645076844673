<div class="main ">
    <div class="s1 container2">
        <div class="d-flex justify-content-between align-items-center">

            <div class="banner w-50">
                <img alt='image' src="/assets/images_new/case-studies/scottedil/s1.svg">
            </div>

            <div class="fontSmall w-25">
                <ul>

                    <li class="listItems"> 1,00,000+ Retailers in the Network</li>
                    <li class="listItems"> Handling a wide variety of products in the pharma Industry</li>
                    <li class="listItems"> Different apps to establish a common bridge for MRs, Customers, Retailers,
                        Stockist and
                        Manufacturer</li>
                </ul>
            </div>

        </div>

        <div class="text2">
            <p>
                A pharmacetical app made to simplify ease of buying
                medicines in just three clicks. The best in the
                industry. It is an American multinational
                pharmaceutical and biotechnology corporation
                headquartered on 42 Street in Manhattan, New
                York City. Established in 1849 in New York by two
                German immigrants Charles Pfizer and his cousin
                Charles E Erhart.
            </p>
        </div>
    </div>

    <div class="s2 container2">
        <div class="row">
            <div class="col-md-6 left">
                <h2>
                    SERVICES
                </h2>
                <div class="listItems">Research</div>
                <div class="listItems">Strategy</div>
                <div class="listItems">Planning</div>
                <div class="listItems">Ideation</div>
                <div class="listItems">Design</div>
                <div class="listItems">Art Direction</div>
                <div class="listItems">Development</div>
                <div class="listItems">Technology</div>
            </div>
            <div class="col-md-6 right">
                <h2>About</h2>
                <p>
                    Scott Edil leads India healthcare space in manufacturing of medicines for brands across the world.
                    They have a large network of 1 lakh+ retailers and constantly engage with millions of customers via
                    their network.
                </p>


                <h2>Problem</h2>
                <p>
                    Scott Edil wanted a common app platform to engage with these retailers directly. On other hand their
                    sales fleet would gather orders manually on paper. The process had become slow and orders would get
                    missed or delayed due to this.
                </p>


                <h2>Solution</h2>
                <p>
                    Build a common platform across mobile and web to onboard these retailers easily and provide their
                    best needs right on the app. This would help to get orders all in 1 place, while launching new
                    schemes and offers to everyone via this app itself. Salespersons would collect orders from the
                    market, and the orders would be assigned to retailers in real-time, giving them an additional boost
                    in revenue. A robust order tracking system would further prevent losses.


                </p>
            </div>
        </div>
    </div>

    <div class="s3 container2">
        <div class="s31">
            <div class="s311">
                <img alt='image' src="/assets/images_new/case-studies/scottedil/s2.svg">
            </div>
        </div>
        <div>
            <img alt='image' src="/assets/images_new/case-studies/scottedil/s3.png">
        </div>
    </div>

    <div class="s4 container2">
        <div class="row">
            <div class="col-12">
                <img alt='image' src="/assets/images_new/case-studies/scottedil/s51.svg">
            </div>
            <div class="col-sm-6">
                <img alt='image' src="/assets/images_new/case-studies/scottedil/s4.svg">
            </div>
            <div class="col-sm-6 ">
                <img alt='image' src="/assets/images_new/case-studies/scottedil/s5.svg">
            </div>

            <div class="col-12">
                <img alt='image' src="/assets/images_new/case-studies/scottedil/s6.svg">
            </div>
            <img alt='image' class="bg" src="/assets/images_new/case-studies/scottedil/sb1.png">

        </div>
    </div>

    <div class="s5 container2">
        <img alt='image' src="/assets/images_new/case-studies/scottedil/s7.svg" class="c51">
        <img alt='image' src="/assets/images_new/case-studies/scottedil/s7.png" class="c52">
    </div>

    <div class="c6">
        <div class="container2">
            <div class="row">
                <div class="col-md-6 left" id="sticky">
                    <h2>
                        High Fidelity Wireframes
                        <img alt='image' src="/assets/images_new/case-studies/scottedil/magnifire.svg" class="c62">
                    </h2>
                    <p>
                        A pharmacetical app made to simplify ease of buying
                        medicines in just three clicks. The best in the industry.
                        It is an is an American multinational pharmaceutical and
                        biotechnology corporation headquartered on 42nd Street in
                        Manhattan, New York City. Established in 1849 in New York
                        by two German immigrants
                        Charles Pfizer and his cousin Charles F. Erhart.
                    </p>
                </div>
            </div>
        </div>
        <img alt='image' src="/assets/images_new/case-studies/scottedil/s8.png" class="c52">
    </div>

    <div class="c7">
        <div class="container2">
            <div class="row c71">
                <div class="col-md-6 left">
                    <img alt='image' src="/assets/images_new/case-studies/scottedil/s9.png">
                </div>
                <div class="col-md-6 right">
                    <h2>Pharmaceutical</h2>
                    <p>A pharmacetical app made to simplify ease of buying
                        medicines in just three clicks. The best in the industry.
                        It is an is an American multinational pharmaceutical
                        and biotechnology corporation headquartered on 42nd
                        Street in Manhattan, New York City. Established in
                        1849 in New York by two German immigrants Charles Pfizer
                        and his cousin Charles F. Erhart.
                    </p>
                </div>
            </div>
            <div class="row c72">
                <div class="col-md-6 left">
                    <h2>Navbar</h2>
                    <p>
                        Simple and clean. We faced a challenge of designing a navbar
                        that needs to be subtle and at the same time satisfactory
                        to the user needs, so we relied on motion to enhance the
                        experience and added color to highlight the functionality.
                    </p>
                </div>
                <div class="col-md-6 right">
                    <img alt='image' src="/assets/images_new/case-studies/scottedil/s10.png">
                </div>
            </div>
        </div>
        <div class="c73 container2">
            <img alt='image' src="/assets/images_new/case-studies/scottedil/s101.svg" class="c731">
            <img alt='image' src="/assets/images_new/case-studies/scottedil/s11.png" class="c732">
        </div>
        <div class="c74">
            <div class="box">
                <h2>Dashboard</h2>
                <p>
                    From buying medicine in just three clicks and get it
                    on your doorstep within a day Scott Edill has covered
                    it all We have designed the app keeping in mind the
                    the necessacity of the healthcare situation and try to
                    deliever medicine at your doorstep in just 12 hours.
                </p>
            </div>
        </div>
        <div class="c75">
            <img alt='image' src="/assets/images_new/case-studies/scottedil/slide1.png">
        </div>
    </div>

    <div class="c8 container2">
        <div class="row c81">
            <div class="col-md-6 left">
                <h2>All App Screens</h2>
                <p>
                    From buying medicine in just three clicks and get
                    it on your doorstep within a day Scott Edill has
                    covered it all We have designed the app keeping in
                    mind the the necessacity of the healthcare situation
                    and try to
                    deliever medicine at your doorstep in just 12 hours.
                </p>
            </div>
        </div>
    </div>

    <div class="c9">
        <img alt='image' src="/assets/images_new/case-studies/scottedil/s12.png">
    </div>

</div>

<div class="nextBtn"
    style="width: 100%;padding-top: 7rem;padding-bottom: 14rem;font-size: 7rem;line-height: 8rem;text-align: center;">
    <span routerLink="/projects/hexabull" style="cursor: pointer;">
        Next
        <img alt='image' src="/assets/images_new/case-studies/arrow.svg" width="100"
            style="margin-left: 3rem; width: 100px !important;">
    </span>
</div>