import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-culture',
  templateUrl: './culture.component.html',
  styleUrls: ['./culture.component.scss']
})
export class CultureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  allValuesImage: Array<string>= [
    // '/assets/images_new/culture/c1.png',
    '/assets/images_new/culture/c2.png',
    '/assets/images_new/culture/c3.png',
    '/assets/images_new/culture/cb4.jpg',
    '/assets/images_new/culture/cb7.jpg',
    '/assets/images_new/culture/cb3.JPG',
    '/assets/images_new/culture/cb6.jpg',
    '/assets/images_new/culture/cb1.jpg',
    '/assets/images_new/culture/cb9.jpeg',
    '/assets/images_new/culture/cb5.jpg',
    '/assets/images_new/culture/cb8.jpg',
    '/assets/images_new/culture/cb2.JPG',
    '/assets/images_new/culture/c5.png',
    '/assets/images_new/culture/c6.png',
    '/assets/images_new/culture/c7.png',
    '/assets/images_new/culture/c8.png',
    '/assets/images_new/culture/c9.png',
    '/assets/images_new/culture/c10.png',
    '/assets/images_new/culture/c11.png',
  ]



  // background color switch
  scrollPosition: number= 0;

  backgroundColorHome: string='#fff';
  colorTextHome: string= '#000';
  showPillars: boolean= false;

  @ViewChild("mainBlock") mainDiv: ElementRef;
  @ViewChild("banner") bannerDiv: ElementRef;
  @ViewChild("values") valuesDiv: ElementRef;
  @ViewChild("ceoDesk") ceoDeskDiv: ElementRef;
  @ViewChild("pillars") pillarsDiv: ElementRef;
  @ViewChild("people") peopleDiv: ElementRef;
  @ViewChild("contactUs") contactDiv: ElementRef;


  // scrooling position 
  ngAfterViewInit(): void {
    let dialogElement = this.mainDiv.nativeElement as HTMLElement;
      dialogElement.onscroll = () => {
          this.scrollPosition= dialogElement.scrollTop;
            this.changeBgColor();
      }
    this.marqueeScroll()
  }

  changeBgColor(){
    // console.log(this.bannerDiv)
    var bannerHeight= Math.max(this.bannerDiv.nativeElement.scrollHeight, this.bannerDiv.nativeElement.offsetHeight, this.bannerDiv.nativeElement.clientHeight);
    var valuesHeight= Math.max(this.valuesDiv.nativeElement.scrollHeight, this.valuesDiv.nativeElement.offsetHeight, this.valuesDiv.nativeElement.clientHeight);
    var ceoDeskHight= Math.max(this.ceoDeskDiv.nativeElement.scrollHeight, this.ceoDeskDiv.nativeElement.offsetHeight, this.ceoDeskDiv.nativeElement.clientHeight);
    var pillarsHeight= Math.max(this.pillarsDiv.nativeElement.scrollHeight, this.pillarsDiv.nativeElement.offsetHeight, this.pillarsDiv.nativeElement.clientHeight);
    var peopleHeight= Math.max(this.peopleDiv.nativeElement.scrollHeight, this.peopleDiv.nativeElement.offsetHeight, this.peopleDiv.nativeElement.clientHeight);
    var contactUsHeight= Math.max(this.contactDiv.nativeElement.scrollHeight, this.contactDiv.nativeElement.offsetHeight, this.contactDiv.nativeElement.clientHeight);

    if(this.scrollPosition<this.getColorChangeHeight(bannerHeight) && this.scrollPosition>0){
      this.backgroundColorHome= "#fff"
      this.colorTextHome= "#000"
    }
    if(this.scrollPosition<this.getColorChangeHeight(valuesHeight)+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(bannerHeight)){
      this.backgroundColorHome= '#000'
      this.colorTextHome= '#fff'
    }
    if(this.scrollPosition<this.getColorChangeHeight(ceoDeskHight)+valuesHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(valuesHeight)+bannerHeight){
      this.backgroundColorHome= '#FFF6EE'
      this.colorTextHome= '#000'
    }
    if(this.scrollPosition<this.getColorChangeHeight(pillarsHeight)+ceoDeskHight+valuesHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(ceoDeskHight)+valuesHeight+bannerHeight){
      this.backgroundColorHome= '#111'
      this.colorTextHome= '#fff';
      this.showPillars= true
    }    
    if(this.scrollPosition<this.getColorChangeHeight(peopleHeight)+pillarsHeight+ceoDeskHight+valuesHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(pillarsHeight)+ceoDeskHight+valuesHeight+bannerHeight){
      this.backgroundColorHome= '#f3f3f3'
      this.colorTextHome= '#000'
    }
    if(this.scrollPosition<this.getColorChangeHeight2(contactUsHeight)+peopleHeight+pillarsHeight+ceoDeskHight+valuesHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight2(peopleHeight)+pillarsHeight+ceoDeskHight+valuesHeight+bannerHeight){
      this.backgroundColorHome= '#fff'
      this.colorTextHome= '#3F3F3F'
    }
  }
  getColorChangeHeight(n: number){
    return n*0.7
  }
  
  getColorChangeHeight2(n: number){
    return n*0.1
  }

  marqueeScroll() {
    const marquees = document.querySelectorAll("div.marquee");
    marquees.forEach(function (element: any) {
      let tick = 1;
      let value = element.dataset.speed;
      // element.innerHTML += element.innerHTML;
      element.innerHTML += element.innerHTML;
      element.innerHTML += element.innerHTML;

      const innerTags = element.querySelectorAll("div.inner");
      innerTags.forEach((inner, index) => {
        inner.style.left = inner.offsetWidth * index + "px";
      });

      const ticker = function () {
        tick += parseInt(value);
        //element.innerHTML = tick;
        //element.style.left = tick + "px";
        innerTags.forEach((inner, index) => {
          let width = inner.offsetWidth;
          let normalizedMarqueeX = ((tick % width) + width) % width;
          let pos = width * (index - 1) + normalizedMarqueeX;

          inner.style.left = pos + "px";
        });
        requestAnimationFrame(ticker);
      };
      ticker();
    });
  }

}
