import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/utils/services/common/common.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  name: any;
  type: any;
  postDetails: any;
  id: any;
  author : any;
  authorName : any;

  allBlogs: Array<any>= [];

  pagination ={
    page: 0,
    limit: 3
  };
  openDetailBlog: any;

  constructor(    
    private route: ActivatedRoute,
    private router: Router,
    private common: CommonService
  ) { 
    if(sessionStorage.getItem('blogID') != null ){
      this.id = sessionStorage.getItem('blogID');
      this.getBlogDetail();
    }
    else{
    this.route.params.subscribe(res => {
      console.log('res',res)
      this.name = res['name'];
      this.type = this.router.url.split('/')[1];
      if (this.type == 'blogs') {
        this.getBlogDetails();
      }
      else {
        this.getProjectDetails();
      }
    })
  }
  }

  ngOnInit(): void {
    this.getBlogs()
  }

  getProjectDetails() {
    console.log(this.name)
    this.common.getProjectDetails(this.name).subscribe(res => {
      this.postDetails = res;
      this.addHtmlContent(this.postDetails);
    })
  }

  getBlogDetails() {
    this.common.getBlogDetails(this.name).subscribe(res => {
      this.postDetails = res;
      console.log(this.postDetails)
      this.addHtmlContent(this.postDetails);

    })
  }

  getBlogDetail(){
    this.common.blogsDetail(this.id).subscribe(res => {
      this.postDetails = res;
      console.log(this.postDetails);
      this.author = this.postDetails._links.author[0].href;
      this.common.getAuthorName(this.author).subscribe(result =>{
       this.authorName = result;
       this.authorName = this.authorName.name;
      })
      sessionStorage.removeItem('blogID')
      this.addHtml(this.postDetails);
    })
  }
  addHtml(post) {
    let html = post.content.rendered;
    let htmlElement = document.getElementById('htmlContent');
    if (htmlElement && html) {
      htmlElement.innerHTML = html;
    }
  }

  addHtmlContent(post) {
    let html = post.html;
    let htmlElement = document.getElementById('htmlContent');
    if (htmlElement && html) {
      htmlElement.innerHTML = html;
    }
  }

  getBlogs() {
    this.common.getBlogs({...this.pagination}).subscribe(res => {
      this.allBlogs = res['blogs'];
    })
  }


  openBlog(blog:any){
    this.router.navigate([`/blogs/${blog.urlTitle}`])
  }



}
