<div class="main">
    <div class="c1 container2">
        <h1>IRCTC</h1>
        <h4>Indian Railway Catering and Tourism Corporation</h4>
    </div>

    <div class="c2 container2">
        <img alt='image' src="/assets/images_new/case-studies/IRCTC/irctc_logo.png">
    </div>


    <div class="c3 container2">
        <div class="row c31">
            <div class="col-md-6 left">
                <div class="section">
                    <h2>About</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="section">
                    <h2>Problem</h2>
                    <p>
                        Help create a better experience for passengers by gathering their data, pre filling relevant
                        data, and data visualisation to get relevant insights.
                    </p>

                </div>
                <div class="section">
                    <h2>Solution</h2>
                    <p>
                        Getting customer feedback data for a giant like IRCTC was a behemoth task. We wanted to build a
                        platform that could get varied data, while also keeping the scale of responses in mind. We built
                        a custom survey app where customers could share their data quickly, while surveyors working even
                        in remote no-internet locations. Our offline storage and syncing capabilities enabled them to go
                        through remote locations, while getting the decision makers real-time preview of data responses
                        and insights.
                    </p>
                </div>
            </div>
            <div class="col-md-6 right">
                <div class="section">
                    <h2>IRCTC -</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <div class="list">
                        <div class="listItem">RESEARCH AND PLANNING</div>
                        <div class="listItem">STRATEGY</div>
                        <div class="listItem">IDEATION</div>
                        <div class="listItem">CONTENT</div>
                        <div class="listItem">BRANDING</div>
                        <div class="listItem">DEVELOPMENT</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>