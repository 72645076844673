<!-- <div class="loader" *ngIf="loading">
    <div>
      <img alt='image' src="../assets/Dual Ring-1s-56px.gif" />
    </div>
</div> -->
<ngx-loading-bar color="#fec028" [includeSpinner]="false" [animationTime]="03" [runInterval]="100" ></ngx-loading-bar>
<!-- <div class="header">
  <app-header-new [textColor]="'#000'"></app-header-new>
</div> -->
<div class="mainBody">
  <router-outlet></router-outlet>
</div>
<!-- <router-outlet></router-outlet> -->