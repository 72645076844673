import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fortune-projects',
  templateUrl: './fortune-projects.component.html',
  styleUrls: ['./fortune-projects.component.scss']
})
export class FortuneProjectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
