<div class="header">
    <app-header-new [textColor]="'#000'"></app-header-new>
</div>
<div class="blogsBody">
    <div class="container2">
        <div class="title-main">
            <span class="title">
                BNI Marketing/Media Power Team
                <!-- <span class="count">{{totalBlogs + blogData.length}}</span> -->
            </span>
        </div>
        <hr>
        <div class="blogs" style="margin-bottom: 0px !important;">
            <div class="blog">
                <div class="blogCard">
                    <div class="imgFrame">
                        <div class="image">
                            <a href="https://wa.me/918376903305?text=Hi, would love to connect with you." target="_blank">
                                <img src="../../../assets/members-pics/akash-singh.jpeg" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="subTitle">
                        <!-- <img alt="image" src="/assets/images_new/blogs/dotBlack.svg" height="10" width="10" > -->
                        Akash Jai Singh
                        <button class="referral-button"> <a href="https://wa.me/918376903305?text=Hi, would love to connect with you." target="_blank"> 
                            Pass a Referral? Know more
                         </a>
                        </button>
                    </div>
                </div>
            </div>

            <div class="blog">
                <div class="blogCard">
                    <div class="imgFrame">
                        <div class="image">
                            <a href="https://wa.me/919971398023?text=Hi, would love to connect with you." target="_blank">
                                <img src="../../../assets/members-pics/Dhananjay Goel.png" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="subTitle">
                        <!-- <img alt="image" src="/assets/images_new/blogs/dotBlack.svg" height="10" width="10" > -->
                        Dhananjay Goel
                        
                        <button class="referral-button"> <a href="https://wa.me/919971398023?text=Hi, would love to connect with you." target="_blank"> 
                            Pass a Referral? Know more
                         </a>
                        </button>

                    </div>
                </div>
            </div>

            <div class="blog">
                <div class="blogCard">
                    <div class="imgFrame">
                        <div class="image">
                            <a href="https://wa.me/918527852829?text=Hi, would love to connect with you." target="_blank">
                                <img src="../../../assets/members-pics/Arjun Jasuja.jpg" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="subTitle">
                        <!-- <img alt="image" src="/assets/images_new/blogs/dotBlack.svg" height="10" width="10" > -->
                        Arjun Jasuja
                        <button class="referral-button">
                            <a href="https://wa.me/918527852829?text=Hi, would love to connect with you." target="_blank">
                                Pass a Referral? Know more
                            </a>
                        </button>
                    </div>
                </div>
            </div>

            <div class="blog">
                <div class="blogCard">
                    <div class="imgFrame">
                        <div class="image">
                            <a href="https://wa.me/919810065398?text=Hi, would love to connect with you." target="_blank">
                                <img src="../../../assets/members-pics/Anurag  Veer Chugh .png" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="subTitle">
                        Anurag  Veer Chugh
                        <button class="referral-button">
                            <a href="https://wa.me/919810065398?text=Hi, would love to connect with you." target="_blank">
                                Pass a Referral? Know more
                            </a>
                        </button>
                    </div>
                </div>
            </div>

            <div class="blog">
                <div class="blogCard">
                    <div class="imgFrame">
                        <div class="image">
                            <a href="https://wa.me/919811133908?text=Hi, would love to connect with you." target="_blank">
                                <img src="../../../assets/members-pics/Ashish Jain.png" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="subTitle">
                        Ashish Jain
                        <button class="referral-button">
                            <a href="https://wa.me/919811133908?text=Hi, would love to connect with you." target="_blank">
                                Pass a Referral? Know more
                            </a>
                        </button>
                    </div>
                </div>
            </div>

            <div class="blog">
                <div class="blogCard">
                    <div class="imgFrame">
                        <div class="image">
                            <a href="https://wa.me/919811420850?text=Hi, would love to connect with you." target="_blank">
                                <img src="../../../assets/members-pics/Shankar De.png" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="subTitle">
                        Shanker De
                        <button class="referral-button">
                            <a href="https://wa.me/919811420850?text=Hi, would love to connect with you." target="_blank">
                                Pass a Referral? Know more
                            </a>
                        </button>
                    </div>
                </div>
            </div>

            <div class="blog">
                <div class="blogCard">
                    <div class="imgFrame">
                        <div class="image">
                            <a href="https://wa.me/919818891223?text=Hi, would love to connect with you." target="_blank">
                                <img src="../../../assets/members-pics/Vishal Sethi.jpeg" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="subTitle">
                        Vishal Sethi
                        <button class="referral-button">
                            <a href="https://wa.me/919818891223?text=Hi, would love to connect with you." target="_blank">
                                Pass a Referral? Know more
                            </a></button>
                    </div>
                </div>
            </div>

            <div class="blog">
                <div class="blogCard">
                    <div class="imgFrame">
                        <div class="image">
                            <a href="https://wa.me/917895210001?text=Hi, would love to connect with you." target="_blank">
                                <img src="../../../assets/members-pics/Prakhar Agrawal.png" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="subTitle">
                        Prakhar Agrawal
                        <button class="referral-button">
                            <a href="https://wa.me/917895210001?text=Hi, would love to connect with you." target="_blank">
                                Pass a Referral? Know more
                            </a>
                        </button>
                    </div>
                </div>
            </div>

            <div class="blog">
                <div class="blogCard">
                    <div class="imgFrame">
                        <div class="image">
                            <a href="https://wa.me/919205423008?text=Hi, would love to connect with you." target="_blank">
                                <img src="../../../assets/members-pics/Naman.jpg" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="subTitle">
                        Naman Gupta
                        <button class="referral-button">
                            <a href="https://wa.me/919205423008?text=Hi, would love to connect with you." target="_blank">
                                Pass a Referral? Know more
                            </a></button>
                    </div>
                </div>
            </div>

            <div class="blog">
                <div class="blogCard">
                    <div class="imgFrame">
                        <div class="image">
                            <a href="https://wa.me/919811136435?text=Hi, would love to connect with you." target="_blank">
                                <img src="../../../assets/members-pics/Nitesh Jawarani.jpg" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="subTitle">
                        Nitesh Jawarani
                        <button class="referral-button">
                            <a href="https://wa.me/919811136435?text=Hi, would love to connect with you." target="_blank">
                                Pass a Referral? Know more
                            </a>
                        </button>
                    </div>
                </div>
            </div>

        </div>
        
        
    </div>
</div>
<div class="container" #contactUs>
    <app-footer-new></app-footer-new>
</div>