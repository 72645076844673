import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { clients } from 'src/app/utils/common/content';
import { CommonService } from 'src/app/utils/services/common/common.service';
import { debounce } from 'lodash';

declare var $;

@Component({
  selector: 'app-home-new',
  templateUrl: './home-new.component.html',
  styleUrls: ['./home-new.component.scss'],
})

export class HomeNewComponent implements OnInit  {

  timelines= [
    {image: '/assets/images_new/timeline/timeline1.svg'},
    {image: '/assets/images_new/timeline/timeline3.svg'},
    {image: '/assets/images_new/timeline/timeline4.svg'},
    {image: '/assets/images_new/timeline/timeline1.svg'},
    {image: '/assets/images_new/timeline/timeline3.svg'},
    {image: '/assets/images_new/timeline/timeline4.svg'},
    {image: '/assets/images_new/timeline/timeline1.svg'},
    {image: '/assets/images_new/timeline/timeline3.svg'},
    {image: '/assets/images_new/timeline/timeline4.svg'},
    {image: '/assets/images_new/timeline/timeline1.svg'},
    {image: '/assets/images_new/timeline/timeline3.svg'},
    {image: '/assets/images_new/timeline/timeline4.svg'},
    {image: '/assets/images_new/timeline/timeline1.svg'},
    {image: '/assets/images_new/timeline/timeline3.svg'},
    {image: '/assets/images_new/timeline/timeline4.svg'},
    {image: '/assets/images_new/timeline/timeline1.svg'},
    {image: '/assets/images_new/timeline/timeline3.svg'},
    {image: '/assets/images_new/timeline/timeline4.svg'},
    {image: '/assets/images_new/timeline/timeline1.svg'},
    {image: '/assets/images_new/timeline/timeline3.svg'},
    {image: '/assets/images_new/timeline/timeline4.svg'},
    {image: '/assets/images_new/timeline/timeline1.svg'},
    {image: '/assets/images_new/timeline/timeline3.svg'},
    {image: '/assets/images_new/timeline/timeline4.svg'},
  ]
  timelineImagesTime: number= -1;
  cultureImageTime1: number= 1;
  cultureImageTime2: number= 1;
  backgroundColorHome: string='#FEF6F0';
  colorTextHome: string= '#111';
  scrollPosition: number= 0;

  allClients= clients;
  allBlogs: Array<any>= [];
  slideNumber: number= 0;

  contentTrailShow: boolean= true
  csBgLight: boolean= false

  showCSCursor: boolean= false;
  shotDotCursor: boolean= true;
  showReadCursor: boolean= false;

  temproute=''

  constructor(
    private commonService: CommonService,
    private router: Router
  ) {
    console.log('heuasdasdjaksdjlkajsd akdjalkdjslakjsd')
  }

  ngOnInit(): void {
    this.changeBgColor = debounce(this.changeBgColor, 100);
    this.getBlogs()
    this.router.events.subscribe(res=>{
      if(this.temproute!='/'&&this.router.url=='/'){
        this.backgroundColorHome='#FEF6F0';
        this.colorTextHome= '#111';
        this.scrollPosition= 0;
        this.contentTrailShow= true;
        this.showCSCursor= false;
        this.shotDotCursor= true;
        this.csBgLight= false
      }
      this.temproute=this.router.url
    })
    this.router.routeReuseStrategy.shouldDetach(undefined);
  }

  @ViewChild("mainBlock") mainDiv: ElementRef;
  @ViewChild("banner") bannerDiv: ElementRef;
  @ViewChild("caseStudy") caseStudyDiv: ElementRef;
  @ViewChild("ourCulture") ourCultureDiv: ElementRef;
  @ViewChild("clients") clientsDiv: ElementRef;
  @ViewChild("blogs") blogsDiv: ElementRef;
  @ViewChild("design") designDiv: ElementRef;
  @ViewChild("contactUs") contactDiv: ElementRef;

    // scrooling position 
  ngAfterViewInit(): void {
    this.setHeightOfSections()
    let dialogElement = this.mainDiv.nativeElement as HTMLElement;
      dialogElement.onscroll = () => {
          this.scrollPosition= dialogElement.scrollTop;
           this.changeBgColor(dialogElement);
      }
    //   dialogElement.ontouchmove = () => {
    //     this.scrollPosition= dialogElement.scrollTop;
    //     console.log(dialogElement.scrollTop, this.scrollPosition)
    //      this.changeBgColor(dialogElement);
    // }
      // this.scrollToService.scrollTo({ offset: this.scrollPosition, container: dialogElement } as ScrollToConfigOptions).toPromise().then(() => {
      // });s
      // this.runourCultureImages1()
      // this.runourCultureImages2()

      // this.removeDynamicScripts()
      this.dynamicLoadJSFiles()
  }

  private dynamicLoadJSFiles(){
    var dynamicScripts = [
      "/assets/js/imagesloaded.pkgd.min.js",
      "/assets/js/TweenMax.min.js",
      "/assets/js/demo.js"
    ];

    for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts [i];
        node.type = 'text/javascript';
        node.className= "dynamicJS989";
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
    }
    
  }
  // removeDynamicScripts(){
  //     var a=document.getElementsByClassName('dynamicJS989')
  //     a[0].remove()
  //     a[0].remove()
  //     a[0].remove()
  // }
  bannerHeight: number= 0;
  caseStudyHeight: number= 0;
  ourCultureHeight: number= 0;
  clientsHeight: number= 0;
  blogsHeight: number= 0;
  designHeight: number= 0;
  contactUsHeight: number= 0;

  setHeightOfSections(){
    this.bannerHeight= Math.max(this.bannerDiv.nativeElement.scrollHeight, this.bannerDiv.nativeElement.offsetHeight, this.bannerDiv.nativeElement.clientHeight);
    this.caseStudyHeight= Math.max(this.caseStudyDiv.nativeElement.scrollHeight, this.caseStudyDiv.nativeElement.offsetHeight, this.caseStudyDiv.nativeElement.clientHeight);
    this.ourCultureHeight= Math.max(this.ourCultureDiv.nativeElement.scrollHeight, this.ourCultureDiv.nativeElement.offsetHeight, this.ourCultureDiv.nativeElement.clientHeight);
    this.clientsHeight= Math.max(this.clientsDiv.nativeElement.scrollHeight, this.clientsDiv.nativeElement.offsetHeight, this.clientsDiv.nativeElement.clientHeight);
    this.blogsHeight= Math.max(this.blogsDiv.nativeElement.scrollHeight, this.blogsDiv.nativeElement.offsetHeight, this.blogsDiv.nativeElement.clientHeight);
    this.designHeight= Math.max(this.designDiv.nativeElement.scrollHeight, this.designDiv.nativeElement.offsetHeight, this.designDiv.nativeElement.clientHeight);
    this.contactUsHeight= Math.max(this.contactDiv.nativeElement.scrollHeight, this.contactDiv.nativeElement.offsetHeight, this.contactDiv.nativeElement.clientHeight);
  }

  changeBgColor(de){
    // console.log(this.bannerDiv)
    const bannerHeight= this.bannerHeight;
    const caseStudyHeight= this.caseStudyHeight;
    const ourCultureHeight= this.ourCultureHeight;
    const clientsHeight= this.clientsHeight;
    const blogsHeight= this.blogsHeight;
    const designHeight= this.designHeight;
    const contactUsHeight= this.contactUsHeight;
    // console.log({
    //   scrollpos: this.scrollPosition,
    //   bannerHeight: bannerHeight,
    //   cs: caseStudyHeight,
    //   och: ourCultureHeight,
    //   clh: clientsHeight
    // })

    if(this.scrollPosition<this.getColorChangeHeight(bannerHeight) && this.scrollPosition>=0){
      // this.backgroundColorHome= "#fdf5ed"
      this.backgroundColorHome= "#FEF6F0"
      this.colorTextHome= "#111";
      this.contentTrailShow= true;
      this.showCSCursor= false;
      this.shotDotCursor= true;
      this.csBgLight= false
    }
    if(this.scrollPosition<this.getColorChangeHeight(caseStudyHeight)+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(bannerHeight)){
      this.backgroundColorHome= '#FEF6F0'
      // this.backgroundColorHome= this.caseStudies[this.slideNumber].backgroundColor
      this.colorTextHome= '#111';
      // this.colorTextHome= '#B7CDBB';
      this.contentTrailShow= false;
      this.showCSCursor= true
      this.shotDotCursor= false
      this.csBgLight= true
    }
    if(this.scrollPosition<this.getColorChangeHeight(ourCultureHeight)+caseStudyHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(caseStudyHeight)+bannerHeight){
      this.backgroundColorHome= '#FFFBF1'
      this.colorTextHome= '#000';
      this.showCSCursor= false
      this.shotDotCursor= true
      this.csBgLight= false
    }
    if(this.scrollPosition<this.getColorChangeHeight(clientsHeight)+ourCultureHeight+caseStudyHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(ourCultureHeight)+caseStudyHeight+bannerHeight){
      this.backgroundColorHome= '#1D1A19'
      this.colorTextHome= '#fff'
      this.showReadCursor= false
      this.shotDotCursor= true
    }    
    if(this.scrollPosition<this.getColorChangeHeight(blogsHeight)+clientsHeight+ourCultureHeight+caseStudyHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(clientsHeight)+ourCultureHeight+caseStudyHeight+bannerHeight){
      this.backgroundColorHome= '#F2F2F2'
      this.colorTextHome= '#000'
      this.showReadCursor= true
      this.shotDotCursor= false
    }
    if(this.scrollPosition<this.getColorChangeHeight(designHeight)+blogsHeight+clientsHeight+ourCultureHeight+caseStudyHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight(blogsHeight)+clientsHeight+ourCultureHeight+caseStudyHeight+bannerHeight){
      // this.backgroundColorHome= '#171717'
      this.colorTextHome= '#3F3F3F'
      this.showReadCursor= false
      this.shotDotCursor= true

    }
    if(this.scrollPosition<this.getColorChangeHeight2(contactUsHeight)+designHeight+blogsHeight+clientsHeight+ourCultureHeight+caseStudyHeight+bannerHeight && this.scrollPosition>=this.getColorChangeHeight2(designHeight)+blogsHeight+clientsHeight+ourCultureHeight+caseStudyHeight+bannerHeight){
      this.backgroundColorHome= '#fff'
      this.colorTextHome= '#3F3F3F'
    }
  }

  getColorChangeHeight(n: number){
    return n*0.7
  }
  getColorChangeHeight2(n: number){
    return n*0.1
  }

  setActiveSlide(event){
    // this.backgroundColorHome= event.backgroundColor;
    this.colorTextHome= event.textColor;
    this.slideNumber= event.index;
    // console.log(event)
    // this.sliderService.setActiveSlide(event.index)
  }


  getBlogs(params?) {
    var pagination ={
      page: 0,
      limit: 5
    };
    this.commonService.getBlogs({...pagination}).subscribe(res => {
      this.allBlogs = res['blogs']
      console.log(this.allBlogs)
    })
  }

  blogDetails(blog) {
    // let title= blog.title.split(' ').join('-')
    this.router.navigate(['/blogs', blog.urlTitle]);
  }
  
  caseStudies: Array<any>=[


    
    {
      image: '/assets/images_new/csSlider/hexabull.png',
      title: 'HEXABULL',
      backgroundColor: '#3D0D06',
      textColor: '#BEDBC3',
      url: 'hexabull',
      description: 'We created a unique online presence for a largely offline brand that reflects its decades of trust and commitment to timelines.'
    },
    {
      image: '/assets/images_new/csSlider/chargebizz.png',
      title: 'CHARGEBIZZ',
      backgroundColor: '#1A1D23',
      textColor: '#BEDBC3',
      url: 'chargebizz',
      description: 'We created a unique online presence for a largely offline brand that reflects its decades of trust and commitment to timelines.'
    },
    {
      image: '/assets/images_new/csSlider/iptse.png',
      title: 'IPTSE',
      backgroundColor: '#261909',
      textColor: '#BEDBC3',
      url: 'iptse',
      description: 'We created a unique online presence for a largely offline brand that reflects its decades of trust and commitment to timelines.'
    },
    {
      image: '/assets/images_new/csSlider/fortune.png',
      title: 'FORTUNE PROJECTS',
      backgroundColor: '#3A050F',
      textColor: '#BEDBC3',
      url: 'fortune-projects',
      description: 'We created a unique online presence for a largely offline brand that reflects its decades of trust and commitment to timelines.'
    },
    {
      image: '/assets/images_new/csSlider/scottedil.png',
      title: 'SCOTT EDIL',
      backgroundColor: '#062C29',
      textColor: '#BEDBC3',
      url: 'scottedil',
      description: 'We created a unique online presence for a largely offline brand that reflects its decades of trust and commitment to timelines.'
    },

    // {
    //   image: '/assets/images_new/csSlider/irctc_logo.png',
    //   title: 'IRCTC',
    //   backgroundColor: '#3D0D06',
    //   textColor: '#BEDBC3',
    //   url: 'irctc',
    //   description: 'We created a unique online presence for a largely offline brand that reflects its decades of trust and commitment to timelines.'
    // },
    // {
    //   image: '/assets/images_new/csSlider/adani.png',
    //   title: 'ADANI',
    //   backgroundColor: '#3D0D06',
    //   textColor: '#BEDBC3',
    //   url: 'adani',
    //   description: 'We created a unique online presence for a largely offline brand that reflects its decades of trust and commitment to timelines.'
    // },
    // {
    //   image: '/assets/images_new/csSlider/hip.png',
    //   title: 'HIP',
    //   backgroundColor: '#3D0D06',
    //   textColor: '#BEDBC3',
    //   url: 'hip',
    //   description: 'We created a unique online presence for a largely offline brand that reflects its decades of trust and commitment to timelines.'
    // },
  ]

  // runourCultureImages1(){
  //   for(let i=0;i<this.ourCultureImages1.length;i++){
  //     this.ourCultureImages1[i].position= 550;
  //     this.ourCultureImages1[i].translate=Math.random()*20;
  //     if(Math.floor(Math.random()*10)%2==0){
  //       this.ourCultureImages1[i].translate= -this.ourCultureImages1[i].translate;
  //     }
  //   }
  //   let n=0, m=-1;
  //   setInterval(() => {
  //     if(this.ourCultureImages1[n]){
  //       this.ourCultureImages1[n].position--;

  //       if(this.ourCultureImages1[n].position==60){
  //         n++
  //         setTimeout(() => {
  //           m++
  //         }, 1000);
  //       }
  //       if(m>=0){
  //         this.ourCultureImages1[m].position--;
  //       }

  //       if(n==this.ourCultureImages1.length){
  //         this.runourCultureImages1()
  //       }
  //       }
  //   }, 1);
  // }
  // runourCultureImages2(){
  //   setTimeout(() => {
  //     for(let i=0;i<this.ourCultureImages2.length;i++){
  //       this.ourCultureImages2[i].position= 550;
  //       this.ourCultureImages2[i].translate=Math.random()*20;
  //       if(Math.floor(Math.random()*10)%2==0){
  //         this.ourCultureImages2[i].translate= -this.ourCultureImages2[i].translate;
  //       }
  //     }
  //     let n=0, m=-1;
  //     setInterval(() => {
  //       if(this.ourCultureImages2[n]){
  //         this.ourCultureImages2[n].position--;
  
  //         if(this.ourCultureImages2[n].position==60){
  //           n++
  //           setTimeout(() => {
  //             m++
  //           }, 1000);
  //         }
  //         if(m>=0){
  //           this.ourCultureImages2[m].position--;
  //         }
  
  //         if(n==this.ourCultureImages2.length){
  //           this.runourCultureImages2()
  //         }
  //         }
  //     }, 1);
  //   }, 0);
  // }

  // getRotateDegree(i){
  //   const temp= [12,18,15,21,5]
  //   return temp[i]
  // }


  // getRotate(i){
  //   var temp= Math.floor(Math.random() * (18 - 5 + 1) + 10)
  //   if(i%2==0){
  //     return temp
  //   }else{
  //     return -temp
  //   }
  // }

}
