<div class="main">
    <div class="c1 container2">
        <h1>Adani</h1>
        <h4>Adani Airports</h4>
    </div>

    <div class="c2 container2">
        <img alt='image' src="/assets/images_new/case-studies/adani/mainONe.png">
    </div>


    <div class="c3 container2">
        <div class="row c31">
            <div class="col-md-6 left">
                <div class="section">
                    <h2>About</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="section">
                    <h2>Problem</h2>
                    <p>
                        Help create a better experience for visitors in solving their problems, helping them navigate
                        and improving their overall experience
                    </p>

                </div>
                <div class="section">
                    <h2>Solution</h2>
                    <p>
                        We created an easy-to-use interface for travellers to get all their important information at 1
                        place. This also gave them a multi-lingual chatbot to get their queries resolved. In addition,
                        they could take help from a real person via video calls and make their travel stress free.
                    </p>
                </div>
            </div>
            <div class="col-md-6 right">
                <div class="section">
                    <h2>Adani -</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <div class="list">
                        <div class="listItem">RESEARCH AND PLANNING</div>
                        <div class="listItem">STRATEGY</div>
                        <div class="listItem">IDEATION</div>
                        <div class="listItem">CONTENT</div>
                        <div class="listItem">BRANDING</div>
                        <div class="listItem">DEVELOPMENT</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>