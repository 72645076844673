<div class="contact">
        <!-- <img alt='image' src="/assets/images_new/designStudio.png" class="studio star_rotate" > -->
        <div class="contactUs">
            <span class="t1">
                <img alt='image' src="/assets/images_new/icons/star.svg" class="star" height="79px" width="79px" >
                Got a project?
            </span>
            <span class="t2" routerLink="/contact-us">
                Contact Us
                <span class="t22">
                    <img alt='image' class="arrow-s" src="/assets/images_new/arrow-C.svg" >
                    <img alt='image' class="circle-s" src="/assets/images_new/circle-C.svg" >
                </span>
            </span>

        </div>
        <hr class="hrs">

        <div class="main row">
            <div class="col-md-6 logo">
                <div class="name" routerLink="/">
                    <!-- <div class="icon" *ngIf="home">
                        <img alt='image' src="/assets/images_new/logo.svg" width="58px" >
                    </div> -->
                    <div class="names" *ngIf="home">
                        <app-logo-kilobyte [fontSize]="30"></app-logo-kilobyte>

                        <div style="width: 220px;">Creative design and development agency</div>
                    </div>
                    <div class="names2" *ngIf="!home">
                        <app-logo-kilobyte [fontSize]="30"></app-logo-kilobyte>
                        <div style="width: 220px;">Creative design and development agency</div>
                    </div>
                </div>
            </div>
            
            <div class="col-md-6 menu">
                <div class="menuList">
                    <div routerLink="/projects">Work</div>
                    <div routerLink="/culture">Culture</div>
                    <div routerLink="/services">Services</div>
                    <div class="primary" routerLink="/contact-us">
                        Contact 
                        <img alt='image' src="/assets/images_new/icons/star.svg" class="star_rotate" height="14px" width="14px" >
                    </div>
                </div>
                <div class="menuList">
                    <div><a href="https://in.linkedin.com/company/hellokilobyte" target="_blank">Linkedin</a></div>
                    <div><a href="https://dribbble.com/HelloKilobyte" target="_blank">Dribble</a></div>
                    <!-- <div><a href="" target="_blank">Behance</a></div> -->
                    <div><a href="https://www.instagram.com/sayhellokilobyte/" target="_blank">Instagram</a></div>
                    <div><a href="https://www.facebook.com/HelloKilobyte" target="_blank">Facebook</a></div>
                </div>
            </div>
        </div>
        <hr class="hrs2">
        <div class="rights">
            <span>Kilobyte Technologies. All rights reserved</span>
        </div>
</div>