import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo-kilobyte',
  templateUrl: './logo-kilobyte.component.html',
  styleUrls: ['./logo-kilobyte.component.scss']
})
export class LogoKilobyteComponent implements OnInit {

  constructor() { }
  @Input() fontSize: number= 300;
  imgHeight: number= 61;

  r = document.querySelector(':root');
  ngOnInit(): void {
    this.imgHeight=  this.fontSize*1.129;
    this.r['style'].setProperty('--fontSize', `${this.fontSize}px`);
    this.r['style'].setProperty('--imgHeight', `${this.imgHeight}px`);
  }

}
