<div class="header">
    <app-header-new [textColor]="colorTextHome">Loading...</app-header-new>
</div>
<div class="body2" #mainBlock [ngStyle]="{backgroundColor: backgroundColorHome, color: colorTextHome}">
    <div class="banner scrolly" #banner>
        <div class="overlay" id="scrollTrail" [ngStyle]="{display: contentTrailShow?'block':'none'}">
            <main>
                <div class="contentTrail">
                    <div class="content_img">
                        <img alt='image' src="/assets/images_new/timeline/s1.png">
                    </div>
                    <div class="content_img">
                        <img alt='image' src="/assets/images_new/timeline/s2.png">
                    </div>
                    <div class="content_img">
                        <img alt='image' src="/assets/images_new/timeline/s3.png">
                    </div>
                    <div class="content_img">
                        <img alt='image' src="/assets/images_new/timeline/s4.png">
                    </div>
                    <div class="content_img">
                        <img alt='image' src="/assets/images_new/timeline/s5.png">
                    </div>
                    <div class="content_img">
                        <img alt='image' src="/assets/images_new/timeline/s6.png">
                    </div>
                    <div class="content_img">
                        <img alt='image' src="/assets/images_new/timeline/s7.png">
                    </div>
                    <div class="content_img">
                        <img alt='image' src="/assets/images_new/timeline/s8.png">
                    </div>
                </div>
            </main>
        </div>
        <!-- <div class="timeline">
            <div class="timelineItem" *ngFor="let timeline of timelines; let i=index">
                {{i+1}} -->
        <!-- <div class="imgContainer"> -->
        <!-- <div class="imgContainer" *ngIf="i==timelineImagesTime" [ngStyle]="{display:i==timelineImagesTime?'block':'none'}"> -->
        <!-- <div class="imgContainer" [ngStyle]="{opacity:(i==timelineImagesTime || i==timelineImagesTime+1 || i==timelineImagesTime+2)?'1':'0',zIndex: (i==(timelineImagesTime+1))?'2':'1'} ">
                    <img [src]="timeline?.image" alt="timeline" [ngStyle]="{opacity: (i==(timelineImagesTime+1))?'1':'0.3' }">
                </div> -->
        <!-- <div class="imgContainer" [ngClass]="(i==timelineImagesTime-1 || i==timelineImagesTime || i==timelineImagesTime+1)?'image1':''">
                    <img [src]="timeline?.image" alt="timeline">
                </div>
            </div> 
        </div>-->

        <div class="container2">
            <!-- <div class="headers ch1">
                <span class="borderText">We craft</span><br>
                <span>
                    Experiences
                    <img alt='image' src="/assets/images_new/icons/star.svg" class="star" height="85px" width="85px" >
                </span>
            </div> -->
            <!-- <div class="headers ch2">
                <span class="borderText">We craft</span><br>
                <span>
                    Experiences
                    <img alt='image' src="/assets/images_new/icons/star.svg" class="star" height="85px" width="85px" >
                </span>
            </div> -->
            <div class="headers">
                <h1>
                    We build products that<br class="br1"> your customers love.<br>
                    & competitors envy.
                    <!-- <img alt='image' src="/assets/images_new/circleBanner.svg" > -->
                    <img alt='image' src="/assets/images_new/Vector-3.gif">
                </h1>
                <h2>Kilobyte is a global B2B Design & Development Agency</h2>
            </div>
        </div>
        <!-- <section class="trail marquee-container">
            <div class="marquee2" data-speed="-1">
              <div class="inner">
                <p>
                    <span>
                        <img alt='image' src="/assets/images_new/starHome.svg" class="star" height="85px" width="85px" >
                        We are a design and development studio based in India who think the evrything is designed good, but some things are designed better. 
                        <img alt='image' src="/assets/images_new/starHome.svg" class="star" height="85px" width="85px" >
                    </span>
                </p>
              </div>
            </div>
        </section> -->
    </div>

    <div class="caseStudies scrolly" #caseStudy style="color:#111 !important">

        <div class="head">
            <h3>Case studies</h3>
        </div>
        <app-custom-slider [sliderData]="caseStudies" [colorText]="colorTextHome" [slideWidth]="60"
            [slideWidthUnit]="'%'" [activeSlide]="0" [bgColor]="backgroundColorHome" [bgLight]="csBgLight"
            (currentSlide)="setActiveSlide($event)"></app-custom-slider>
        <div class="carouselData">
            ART DIRECTION / CONTENT CREATION / INTERFACE DESIGN / INTERACTION DESIGN
        </div>
    </div>

    <div class="ourCulture scrolly container" #ourCulture>
        <!-- <div class="head">
            <h3>Our Culture</h3>
        </div>
        <div class="menu">
            <div>We connect</div>
            <div>with <span class="client">Clients</span></div>
            <div class="people">
                People
                <img alt='image' src="/assets/images_new/icons/peopleBackground.svg" >
            </div>
        </div>
        <div class="gallery">
            <div class="image" *ngFor="let image of ourCultureImages1;let i=index" [ngClass]="(cultureImageTime1==i || cultureImageTime1+1==i )?'scrollGallery':''">
                <img alt='image' [src]="image?.image"  width="246" height="316" [ngStyle]="{'left': '40px'}" style="transform: rotate({{image?.rotate}}deg);">
            </div>
            <div class="image" *ngFor="let image of ourCultureImages2;let i=index" [ngClass]="(cultureImageTime2==i || cultureImageTime2+1==i )?'scrollGallery':''">
                <img alt='image' [src]="image?.image"  width="246" height="316" [ngStyle]="{right: '40px'}" style="transform: rotate({{image?.rotate}}deg);">
            </div>
        </div>-->
        <!-- <div>
            Most times <span class="bold1">we only see today.</span><br>
            Yet the <span class="bold1">greatest asset to people,</span> 
            and to businesses <span class="bold1">is to have courage to build.</span>
            We help brands <span class="primary">build a better world. </span>
        </div>     -->
        <div>
            Today’s challenges <span class="bold1">shape tomorrow’s innovations.</span><br>
            At Kilobyte, <span class="bold1">We empower businesses</span> to dream bigger and
            scale smarter. Our systems don’t just solve problems — <span class="bold1 primary">they
                unlock potential</span>
        </div>


    </div>

    <div class="clients scrolly" #clients>
        <div class="head container2">
            <h3>
                <img alt='image' src="/assets/images_new/clientLine.svg">
                Since 2007 we have worked <br> with success partners
            </h3>
        </div>

        <div class="tech-slideshow">
            <div class="mover-1">
                <p>
                    <span *ngFor="let client of allClients?.first">
                        <img alt='image' [src]="client.color" class="color">
                        <img alt='image' [src]="client.gray" class="gray">
                    </span>
                </p>
            </div>
            <div class="mover-2">
                <p>
                    <span *ngFor="let client of allClients?.first">
                        <img alt='image' [src]="client.color" class="color">
                        <img alt='image' [src]="client.gray" class="gray">
                    </span>
                </p>
            </div>
        </div>

        <!-- <section class="marquee-container">
            <div class="marquee" data-speed="-1" style="width: 50%;">
                <div class="inner">
                    <p *ngFor="let client of allClients?.first">
                        <span >
                            <img alt='image' [src]="client.color"  class="color">
                            <img alt='image' [src]="client.gray"  class="gray">
                        </span>
                    </p>
                </div>
            </div>
        </section> -->
    </div>

    <div class="ourBlogs scrolly" #blogs>
        <div class="head container">
            <h3>Our Blogs</h3>
        </div>
        <!-- <img alt='image' src="/assets/images_new/designStudio.png" class="studio star_rotate" > -->

        <div class="blog-list container">
            <div *ngFor="let blog of allBlogs;let i =index" class="single-blog" (click)="blogDetails(blog)">
                <div class="row">
                    <div class="col-md-2">
                        <p class="date">{{blog?.createdAt | date:'MMM d, y'}}</p>
                    </div>
                    <div class="col-md-6">
                        <div class="blog-text">
                            <h1>{{blog?.title}}</h1>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="img-div" *ngIf="blog?.signedUrl && blog?.signedUrl?.length>0">
                            <div class="imgCont">
                                <img alt='image' [src]="blog?.signedUrl[0].signedUrl" class="imgs"
                                    *ngIf="blog?.signedUrl[0]" />
                                <!-- <img alt='image' src="/assets/images_new/scottedil.png" class="imgs" *ngIf="blog?.image[0]"/> -->
                            </div>
                            <img alt='image' src="/assets/images_new/blogs/blog_arrow.svg" class="arn" />
                            <img alt='image' src="/assets/images_new/blogs/blog_arrow_hover.svg" class="arh" />

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="seeMore">
            <span routerLink="/blogs">SeeMore</span>
        </div>
    </div>

    <div class="design scrolly" #design>
        <div class="container">
            <div class="head ">
                <h3>Easy to Understand.</h3>
                <h3 class="white">Unmatched in <span>Impact.</span></h3>
                <!--                 
                <h3>We don't just design</h3>
                <h3 class="white">We design <span>BETTER.</span></h3> -->
            </div>
            <div>
                <img alt='image' src="/assets/images_new/icons/design3.gif">
            </div>
        </div>
    </div>

    <div class="contact scrolly container" #contactUs>
        <app-footer-new></app-footer-new>
    </div>
</div>



<!-- 
<script src="/assets/js/imagesloaded.pkgd.min.js"></script>
<script src="/assets/js/TweenMax.min.js"></script>
<script src="/assets/js/demo.js"></script> -->