import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-irctc',
  templateUrl: './irctc.component.html',
  styleUrls: ['./irctc.component.scss']
})
export class IrctcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
