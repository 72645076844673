import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/utils/services/common/common.service';
import { SnackbarService } from 'src/app/utils/services/snackbar/snackbar.service';

@Component({
  selector: 'app-contact-us-new',
  templateUrl: './contact-us-new.component.html',
  styleUrls: ['./contact-us-new.component.scss']
})
export class ContactUsNewComponent implements OnInit {

  contact={
    name: '',
    email: '',
    message: ''
  }

  constructor(
    private common: CommonService,
    private msg: SnackbarService
  ) { }

  ngOnInit(): void {
  }
  submit(){
    console.log(this.contact)
    this.common.contactUs(this.contact).subscribe(res => {
      console.log(res)
      this.msg.openSnackBar('Thank you for your submission. Will contact you soon');
      this.contact= {
        name: '',
        email: '',
        message: ''
      }
    }, (err) => {
    })
  }

}
