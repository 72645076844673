<div class="main">
    <div class="c1 container2">
        <img alt='image' src="/assets/images_new/case-studies/iptse/IPTSE.png" class="logo" >
        <div class="desc">
            ui/ux study
        </div>
        <img alt='image' src="/assets/images_new/case-studies/iptse/i1.png" >
    </div>


    <div class="c2 row">
        <div class="col-md-6 left">
            <img alt='image' src="/assets/images_new/case-studies/iptse/i2.svg" >
        </div>
        <div class="col-md-6 right">
            <img alt='image' src="/assets/images_new/case-studies/iptse/i3.svg" >
        </div>
    </div>
    
    <div class="c3 container2">
        <img alt='image' src="/assets/images_new/case-studies/iptse/i4.svg" >
    </div>  

    <div class="c4">
        <img alt='image' src="/assets/images_new/case-studies/iptse/i5.svg" >
    </div>

    <div class="c5">
        <img alt='image' src="/assets/images_new/case-studies/iptse/e1.png" class="e1" >
        <img alt='image' src="/assets/images_new/case-studies/iptse/e2.png" class="e2" >
        <img alt='image' src="/assets/images_new/case-studies/iptse/e3.png" class="e3" >

        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-3">
                <div class="listHead">Client</div>
                <div class="listItem">IPTSE</div>
            </div>
            <div class="col-md-3">
                <div class="listHead">Services</div>
                <div class="listItem">UX Research</div>
                <div class="listItem">UI/Visual Design</div>
                <div class="listItem">Development</div>
            </div>
            <div class="col-md-3">
                <div class="listHead">Deliverables</div>
                <div class="listItem">Web app</div>
                <div class="listItem">Mobile app</div>
                <div class="listItem">Development</div>
            </div>
        </div>
    </div>

    <div class="c6">
        <img alt='image' src="/assets/images_new/case-studies/iptse/i6.png" >
    </div>
    <div class="c6">
        <img alt='image' src="/assets/images_new/case-studies/iptse/ericson.png" >
    </div>

    <div class="c7">
        <img alt='image' src="/assets/images_new/case-studies/iptse/i17.svg" >
        <div class="row cards">
            <div class="col-md-4 cCardContainer">
                <div class="cCard" style="background-color: #0C87F9;">
                    <img alt='image' src="/assets/images_new/case-studies/iptse/i7.svg" >
                    <div class="cardHead">Increase customers</div>
                    <div class="desc">
                        Website being the first point of contact, the redesign had to increase conversions by offering their services eloquently with distinct Call to Actions.
                    </div>
                </div>
            </div>
            <div class="col-md-4 cCardContainer">
                <div class="cCard" style="background-color: #FFD707;color: #111">
                    <img alt='image' src="/assets/images_new/case-studies/iptse/i8.svg" >
                    <div class="cardHead">Increase customers</div>
                    <div class="desc">
                        Building carrer is one of the most important aspect of someones life. We wanted IPTSE website to be that trust worthy and carrer worthy website.                    </div>
                </div>
            </div>
            <div class="col-md-4 cCardContainer">
                <div class="cCard" style="background-color: #FF5A26;">
                    <img alt='image' src="/assets/images_new/case-studies/iptse/i9.svg" >
                    <div class="cardHead">Upgrading the Visuals </div>
                    <div class="desc">
                        The visual languiage plays an important role in communicating ideas. We had to create a new extensible visual language for their brand.                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="c8">
        <img alt='image' src="/assets/images_new/case-studies/iptse/i10.png" >
    </div>

    <div class="c9">
        <div class="row">
            <div class="col-md-6">
                <img alt='image' src="/assets/images_new/case-studies/iptse/i11.png" >
            </div>
            <div class="col-md-6 right">
                <div class="card10">
                    <p>
                        <b>Clear illustrations, warm colors and an eager to learn attitude.</b> 
                            IPTSE is not just a platform to crack olympiad. 
                            This is where careers are made and people actually get a 
                            national level certificate which they can add on the resume 
                            and portfolio.
                    </p>
                </div>
            </div>
        </div>
    </div>
        
    <div class="c10">
        <img alt='image' src="/assets/images_new/case-studies/iptse/i12.png" >
    </div>
        
    <div class="c11">
        <img alt='image' src="/assets/images_new/case-studies/iptse/i13.png" >
    </div>
    
    <div class="c12">
        <div class="row">
            <div class="col-md-6 right">
                <div class="card10">
                    <p>
                        <b>Clear iconography, rounded corners have been used in the navbar. </b>
                            The icon selected will change its color to orange with an 
                            underlying shadow effect to enhance the selection effect.
                    </p>
                </div>
            </div>
            
            <div class="col-md-6">
                <img alt='image' src="/assets/images_new/case-studies/iptse/i14.png" >
            </div>
        </div>
    </div>

    <div class="c13">
        <img alt='image' src="/assets/images_new/case-studies/iptse/i15.png" >
    </div>

    <div class="c14">
        <p class="desc">
            The IPTSE Web app and Mobile app design and development was 
            completed after a thorough in depth process which varied from UX Research to making sitemaps all the way to low fidelity wireframes and upgrading to high fidelity wireframes. Various iterations of visual design were made before deciding the final one. The develpment team 
            faced many challenges during the upbringing of the website.
        </p>
        <div class="comp">
            <img alt='image' src="/assets/images_new/case-studies/iptse/i16.svg" >
            <h1>Thank You</h1>
        </div>
    </div>

</div>

<div class="nextBtn" style="width: 100%;padding-top: 7rem;padding-bottom: 14rem;font-size: 7rem;line-height: 8rem;text-align: center;">
    <span routerLink="/projects/scottedil" style="cursor: pointer;">
        Next
        <img alt='image' src="/assets/images_new/case-studies/arrow.svg" width="100" style="margin-left: 3rem; width: 100px !important;" >
    </span>
</div>