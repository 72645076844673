import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  themeColor: string= "#6a78d1";
  textColor: string= "#00f";
  linkColor: string= "#6a78d1";
  fontFamily: string= "Arial";
  fontSize: number= 0;
  font: number= 0;

  imgUrl= "https://kilobytetech.com"
  userData= {
    name: 'AnuragAnuragAnuragAnurag',
    jobTitle: 'Anurag',
    department: 'Anurag',
    companyName: 'Anurag',
    officePhoneNumber: 'Anurag',
    phoneNumber: 'Anurag',
    websiteUrl: 'Anurag',
    emailAddress: 'Anurag',
    address: 'Anurag',
    socialLinks: {
      linkedin: 'Anurag',
      facebook: 'Anurag',
      twitter: 'Anurag',
      instagram: 'Anurag'
    },
    customField: 'Anurag',
    profileImage: 'https://res.cloudinary.com/dacwh4kfv/image/upload/v1635333760/Rectangle_2068_fi75st.png',
    companyLogo: 'https://res.cloudinary.com/dacwh4kfv/image/upload/v1635333760/Rectangle_2068_fi75st.png'
  }
  createdCheck=true

}
