import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {gsap} from 'gsap';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  menu;
  @Input() active;
  @Output() closeMenu = new EventEmitter();
  get showMenu(): boolean {
    return this.menu;
  }
  @Input() set showMenu(value: boolean) {
    this.menu = value;
    if (this.menu) {
      this.gsapAnimation('0%');
    }
    else {
      this.gsapAnimation('100%');
    }
  }
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  gsapAnimation(yValue) {
    const tl = gsap.timeline({ defaults: { ease: "power1.out" } });
    tl.to(".text", { y: yValue, duration: 0.5,delay:0.2, stagger: 0.25 });
  }

  close() {
    this.closeMenu.emit();
  }

  navi(temp:string){
    this.router.navigate([temp])
  }

}
