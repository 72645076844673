<div class="main">
    <div class="c1 container2">
        <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/logo.svg" >
    </div>
    <div class="c2 container2">
        <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c1.png" >
    </div>

    <div class="c3 container2">
        <div class="row c31">
            <div class="col-md-6 left">
                <div class="section">
                    <h2>About</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="section">
                    <h2>Problem</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="section">
                    <h2>Solution</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
            <div class="col-md-6 right">
                <div class="section">
                    <h2>Crate Caffinate</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <div class="list">
                        <div class="listItem">RESEARCH AND PLANNING</div>
                        <div class="listItem">STRATEGY</div>
                        <div class="listItem">IDEATION</div>
                        <div class="listItem">CONTENT</div>
                        <div class="listItem">BRANDING</div>
                        <div class="listItem">UI/UX DESIGN</div>
                        <div class="listItem">ART DIRECTION</div>
                        <div class="listItem">DEVELOPMENT</div>
                    </div>
                    <div class="visit">
                        Visit site 
                        <img alt='image' src="/assets/images_new/case-studies/hma/arrow-right.svg" height="12" >
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="c4">
        <div class="c41 container2">
            <div class="left">
                <div class="text">Typography</div>
            </div>
            <div class="right row">
                <div class="col-md-6 c411">
                    <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c2.svg" >
                </div>
                <div class="col-md-6 c412">
                    <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c3.svg" >
                </div>
            </div>
        </div>
        <div class="c42 container2">
            <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c4.png" >
            <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c5.png" >
        </div>
    </div>

    <div class="c5">
        <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c6.png" >
        <div class="c51">
            <div class="card52">
                <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c7.png" >
            </div>
            <div class="card52">
                <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c8.png" >
            </div>
        </div>
    </div>

    <div class="c6">
        <div class="container2">
            <div class="c61 row">
                <div class="col-md-6 left" id="sticky">
                    <h2>Strategy</h2>
                    <div class="listItems">Data Analysis</div>
                    <div class="listItems">UX</div>
                    <div class="listItems">Visual Design</div>
                </div>
                <div class="col-md-6 right">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
            <div class="custCards row">
                <div class="col-md-4 cContainer">
                    <div class="cCard">
                        <div class="bg1" style="background-color: #FFE7C4;"></div>
                        <div class="bg2" style="background-color: #010000;"></div>
                        <div class="cData" style="color: #fff">
                            <div class="title">
                                Data Analysis
                            </div>
                            <div class="dataList">Identify the competition</div>
                            <div class="dataList">Market analysis</div>
                            <div class="dataList">Users</div>
                            <div class="dataList">Scalability</div>
                            <div class="dataList">Future growth</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 cContainer">
                    <div class="cCard">
                        <div class="bg1" style="background-color: #FFE7C4;"></div>
                        <div class="bg2" style="background-color: #EE9713;"></div>
                        <div class="cData" style="color: #fff">
                            <div class="title" style="border-color: #fff;">
                                UX
                            </div>
                            <div class="dataList">UX Reseatch</div>
                            <div class="dataList">Brainstroming</div>
                            <div class="dataList">Ideation</div>
                            <div class="dataList">Content</div>
                            <div class="dataList">Wireframing</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 cContainer">
                    <div class="cCard">
                        <div class="bg1" style="background-color: #FFE7C4;"></div>
                        <div class="bg2" style="background-color: #010000;"></div>
                        <div class="cData" style="color: #fff">
                            <div class="title">
                                Visual Design
                            </div>
                            <div class="dataList">Branding</div>
                            <div class="dataList">UI/Visual Design</div>
                            <div class="dataList">Interface Design</div>
                            <div class="dataList">Experience Design</div>
                            <div class="dataList">Micro animations</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="c7">
        <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c9.png" >
    </div>

    <div class="c8">
        <div class="container2">
            <div class="c81 row">
                <div class="col-md-6 left" id="sticky">
                    <h2>The most flexible Coffee App ever built</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="col-md-6 right">
                    <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c10.png" >
                </div>
            </div>
        </div>
    </div>
    <div class="c9">
        <div class="container2">
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Justo convallis urna, sed etiam eget ac enim laoreet scelerisque. Pharetra et id commodo, nisi. Adipiscing ut quisque convallis congue maecenas. Odio neque et nulla eros, eleifend nunc diam blandit.
            </p>
            <div class="imgGroup">
                <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c14.png" >
                <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c13.png" >
                <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c12.png" >
                <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c11.png" >
            </div>
        </div>
    </div>

    <div class="c10">
        <div class="container2">
            <div class="c61 row">
                <div class="col-md-6 left" id="sticky">
                    <h2>Color Iterations</h2>
                </div>
                <div class="col-md-6 right">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="c11">
        <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c15.png" >
    </div>

    <div class="c12">
        <div class="container2">
            <div class="c61 row">
                <div class="col-md-6 left" id="sticky">
                    <h2>Profile screens</h2>
                </div>
                <div class="col-md-6 right">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="c13 row">
        <div class="c131 container2">
            <div class="card52">
                <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c16.png" >
            </div>
            <div class="card52">
                <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c17.png" >
            </div>
        </div>
        <div class="c131 container2">
            <div class="card52">
                <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c18.png" >
            </div>
            <div class="card52">
                <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c19.png" >
            </div>
        </div>
    </div>

    <div class="c14">
        <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c20.png" >
    </div>

    <div class="c15">
        <div class="container2">
            <div class="c151 row">
                <div class="col-md-6 left" id="sticky">
                    <h2>Profile screens</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                        aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
                    </p>
                </div>
                <div class="col-md-6 right">
                    <img alt='image' src="/assets/images_new/case-studies/crateCaffinate/c21.png" >
                </div>
            </div>
        </div>
    </div>

</div>

<div class="nextBtn" style="width: 100%;padding-top: 7rem;padding-bottom: 14rem;font-size: 7rem;line-height: 8rem;text-align: center;">
    <span routerLink="/projects/fortune-projects" style="cursor: pointer;">
        Next
        <img alt='image' src="/assets/images_new/case-studies/arrow.svg" width="100" style="margin-left: 3rem; width: 100px !important;" >
    </span>
</div>