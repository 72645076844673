import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { SeoService } from './utils/services/seo/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'kilobyte-website';
  
  constructor(
      @Inject(PLATFORM_ID) private platformId: Object,
      private router: Router,
       viewportScroller: ViewportScroller,
       private _seoService: SeoService,
       private activatedRoute: ActivatedRoute
      ) {
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        if(evt.url!='/contact-us#contact')
          window.scrollTo(0, 0);
      });
    }
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
     )
     .subscribe((event) => {
       this._seoService.updateTitle(event['title']? event['title'] : 'Kilobyte Technologies-');
       this._seoService.updateOgUrl(event['url']? event['url'] : 'https://kilobytetech.com');
       this._seoService.updateDescription(event['description'] ? event['description']  :'We build products that your customers love.')
     });
  }

}
