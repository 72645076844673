<!DOCTYPE html>
<html>
    <head>
        <title>Email Signature</title>
    </head>
    <body style="background-color: #fff !important;">
        <div style="background-color: #faf8ec;text-align: center;font-family: sans-serif;max-width: 680px;margin: auto;">
            <div style="margin-bottom: 53px;padding: 50px;padding-bottom: 0;">
                <div style="display: inline-block;width: 100%;text-align: center;font-size: 30px;font-weight: 700;letter-spacing: -0.08rem;color:#111;">
                    kilobyte<span style="color: #f53700;font-family: Epilogue;">.</span>
                </div>
            </div>
            <div style="margin-bottom: 53px;padding: 0 50px;">
            <img src="https://res.cloudinary.com/dacwh4kfv/image/upload/v1635333759/Group_332081_adejed.png" style="max-width: 80%;width: 400px;" alt="">
            </div> 
            
            <h2 style="font-size: 40px;line-height: 46px;font-weight: 700;letter-spacing: 0.02em;margin-bottom: 25px;padding: 0 60px;color:#111">
                CREATE YOUR OWN EMAIL SIGNATURE IN 30 SEC’s
            </h2> 
    
            <div style="margin-bottom: 55px;">
                <a href="https://kilobytetech.com/tools/email-signature-creator">
                    <img src="https://res.cloudinary.com/dacwh4kfv/image/upload/v1635333759/Group_33359_1_sifc4m.png" style="width: 310px;max-width: 90%;" alt="">
                </a>
            </div>
            <div style="margin-bottom: 60px;">
                <img src="https://res.cloudinary.com/dacwh4kfv/image/upload/v1636609116/Untitled-1_xpvq44.png" style="width: 510px;max-width: 100%;" alt="">
            </div>
            <hr style="margin-bottom: 20px !important;background-color: #000;margin: 0 50px;">

            <h2 style="margin:0;margin-bottom: 30px; position: relative;font-size: 36px;font-weight: 700;color:#111">
                Check Out Our Blogs
            </h2>
            <div style="margin-bottom: 64px;padding: 0 50px;">
                <div style="display: inline-block;width: 48%;margin:0;margin-right: 1%;">
                    <a href="https://kilobytetech.com/blogs" style="cursor: pointer;">
                        <img src="https://res.cloudinary.com/dacwh4kfv/image/upload/v1635333759/Rectangle_2070_fvjozp.png" alt="" style="width: 98%;">
                    </a>
                </div>
                <div style="display: inline-block;width: 48%;margin:0;margin-right: 1%;">
                    <a href="https://kilobytetech.com/blogs" style="cursor: pointer;">
                        <img src="https://res.cloudinary.com/dacwh4kfv/image/upload/v1635333760/Rectangle_2068_fi75st.png" alt="" style="width: 98%;">
                    </a>
                </div>            
                <div style="display: inline-block;width: 48%;margin:0;margin-right: 1%; text-align: left;font-size: 16px;line-height: 24px;color: #111;text-decoration: none;">
                    <a href="https://kilobytetech.com/blogs" style="cursor: pointer;color: #111;text-decoration: none;padding-left: 1%;">
                        Why your business needs an Email Signature?
                    </a>
                </div>
                <div style="display: inline-block;width: 48%;margin:0;margin-right: 1%;text-align: left;font-size: 16px;line-height: 24px;color: #111;text-decoration: none;">
                    <a href="https://kilobytetech.com/blogs" style="cursor: pointer;color: #111;text-decoration: none;padding-left: 1%;">
                        How Email Signatures can help increase your brand presence?
                    </a>
                </div>
            </div>
            <h3 style="margin-bottom: 26px;font-size: 24px;line-height: 31px;color: #111;">
                KILOBYTE TECHNOLOGIES
            </h3>
            <h4 style="margin-bottom: 20px;font-size: 24px;line-height: 31px;font-weight: 400;color:#FF7144;cursor: pointer;">
                <a style="color:#FF7144;" href="https://kilobytetech.com">www.kilobytetech.com</a>
            </h4>
            <div>
                <div style="width: 200px;margin: 0 auto;padding: 0;padding-bottom: 50px;">
                    <a href="https://www.facebook.com/HelloKilobyte" style="width: 24px;margin-right: 20px;cursor: pointer;text-decoration: none;">
                        <img src="https://res.cloudinary.com/dacwh4kfv/image/upload/v1636626238/Group_332083_1_bzajxh.png" style="height: 24px;" alt="">
                    </a>
                    <a href="https://twitter.com/HelloKilobyte" style="width: 24px;margin-right: 20px;cursor: pointer;text-decoration: none;">
                        <img src="https://res.cloudinary.com/dacwh4kfv/image/upload/v1636626238/Frame_91_caahn3.png" style="height: 24px;" alt="">
                    </a>
                    <a href="https://in.linkedin.com/company/hellokilobyte" style="width: 24px;margin-right: 20px;cursor: pointer;text-decoration: none;">
                        <img src="https://res.cloudinary.com/dacwh4kfv/image/upload/v1636626238/Frame_88_dx8qjb.png" style="height: 24px;" alt="">
                    </a>
                    <a href="https://www.instagram.com/sayhellokilobyte/" style="width: 24px;margin-right: 20px;cursor: pointer;text-decoration: none;">
                        <img src="https://res.cloudinary.com/dacwh4kfv/image/upload/v1636626238/Frame_90_h0ny0q.png" style="height: 24px;" alt="">
                    </a>
                </div>
            </div>
        </div>
    </body>
</html>